@if (form) {
  <div
    [@fadeAnimationSequence]
    (@fadeAnimationSequence.done)="animationDone = true"
    uid="addData__additionalInfo"
    [class.loaded]="animationDone"
    [class.allTrackersLoaded]="allTrackersAnimationDone"
  >
    <ion-grid class="addData__form__additionalInfo goal--{{ Goal[config.goal!] }}">
      <!-- Deviating temperature -->
      @if (config.deviationReasonFlags; as deviationReasons) {
        <ion-row class="animated">
          <ion-col>
            <ion-item-group
              class="addData__deviation"
              [ngClass]="'addData__deviation--' + deviationReasons.length"
            >
              @for (reason of deviationReasons; track $index) {
                <ion-item
                  [id]="'addData__deviationReason__' + DataFlag[reason]"
                  color="clear"
                  lines="none"
                  class="ion-no-padding addData__deviationItem"
                  (click)="onDeviationClick(reason)"
                  haptic
                >
                  @if ({ checked: isDataFlagChecked(reason) }; as data) {
                    <div class="addData__dataFlagContainer">
                      <ion-checkbox
                        [attr.uid]="'addData__deviationReason__' + DataFlag[reason]"
                        class="addData__dataFlagCheckbox"
                        [checked]="data.checked"
                        (ionChange)="onDeviationChange($any($event), reason)"
                        [attr.aria-label]="'txt-dataflag--' + DataFlag[reason] | tr"
                      />

                      @if (reason === DataFlag.DEVIATION_REASON_SLEEP) {
                        <div class="addData__deviationIconsContainer">
                          <ion-icon
                            class="addData__deviationIcon"
                            [class.addData__deviationIcon--checked]="data.checked"
                            [src]="ICON_BY_DATAFLAG[reason]"
                            aria-hidden="true"
                          />

                          @if (!data.checked) {
                            <ion-icon
                              class="addData__deviationIcon addData__deviationIcon--outline"
                              [src]="ICON.HELP_SYMBOL"
                              (click)="openDeviationSleepModal()"
                              [attr.aria-label]="'txt-dataflag--' + DataFlag[reason] | tr"
                            />
                          }
                        </div>
                      } @else {
                        <ion-icon
                          class="addData__deviationIcon"
                          [class.addData__deviationIcon--checked]="data.checked"
                          [src]="ICON_BY_DATAFLAG[reason]"
                          aria-hidden="true"
                        />
                      }

                      <div class="addData__dataFlagTitle">
                        {{ 'txt-dataflag--' + DataFlag[reason] | tr }}
                      </div>
                    </div>
                  }
                </ion-item>
              }
            </ion-item-group>
          </ion-col>
        </ion-row>
      }

      <!-- No longer pregnant-button (show if pregnant && prevent or pregnant more than 8 months)  -->
      @if (config.showNoLongerPregnant) {
        <ion-row class="animated">
          <ion-col class="ion-text-center">
            <ion-button
              uid="addData__noLongerPregnant__button"
              class="addData__form__button"
              (click)="noLongerPregnantClicked()"
              haptic
            >
              <ion-label>{{ 'add-data-no-longer-pregnant' | tr }}</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      }

      <!-- Mens -->
      @if (config.mensSegments; as mensSegments) {
        <ion-row class="animated">
          <ion-col>
            <h3 class="addData__form__subtitle">{{ 'txt-bleeding' | tr }}</h3>

            <div
              [tour]="TourTooltip.RECOVERY_3_1"
              style="position: absolute; left: 50%; margin-left: -40px"
            ></div>

            <app-super-segment
              [ready]="ready"
              [items]="mensSegments"
              [value]="{ value: entry.mens, option: entry.mensQuantity }"
              [color]="ColorBySegment.BLEEDING"
              (valueChange)="onMensChange($event)"
            />

            <div
              [tour]="TourTooltip.APP_6_2"
              style="position: absolute; left: 40%"
              tooltipPosition="bottom"
            ></div>
          </ion-col>
        </ion-row>
      }

      <!-- Sex -->
      @if (config.sexSegments; as sexSegments) {
        <ion-row class="animated">
          <ion-col>
            <h3 class="addData__form__subtitle">
              {{
                (config.goal === Goal.PREVENT || config.goal === Goal.POSTPARTUM
                  ? 'txt-sex'
                  : 'txt-sex-plan'
                ) | tr
              }}
            </h3>

            <!-- Show super-segment if Goal.PREVENT or if 'extra sex data' disabled -->
            <app-super-segment
              [ready]="ready"
              [items]="sexSegments"
              [value]="{
                value: entry.sex,
                option: entry.sexType,
              }"
              [color]="ColorBySegment.SEX"
              (valueChange)="onSexSuperSegmentChange($event)"
            />
          </ion-col>
        </ion-row>
      }

      <!-- Other Sex or sex for Plan/Follow with 'extra sex data' enabled -->
      @if (config.otherSexItems; as otherSexItems) {
        <ion-row class="animated">
          <ion-col>
            <h3 class="addData__form__subtitle">
              {{
                (config.goal === Goal.PREVENT || config.goal === Goal.POSTPARTUM
                  ? 'txt-sex-other'
                  : 'txt-sex-plan'
                ) | tr
              }}
            </h3>

            <ion-item-group class="addData__otherSex">
              @for (sex of otherSexItems; track $index) {
                @if (
                  {
                    checked:
                      (!HadSex[sex.value] && isDataFlagChecked(sex.value)) ||
                      sex.value === form.controls[AdditionalDataControl.SEX].value,
                  };
                  as data
                ) {
                  <ion-item
                    [id]="'addData__sex__' + (HadSex[sex.value] || DataFlag[sex.value])"
                    [disabled]="
                      (sex.value === HadSex.NO &&
                        (sexNoneDisabled ||
                          form.controls[AdditionalDataControl.SEX].value === HadSex.YES)) ||
                      (sex.value !== HadSex.NO && otherSexDisabled)
                    "
                    color="clear"
                    lines="none"
                    class="ion-no-padding addData__otherSexItem"
                    [class.addData__otherSexItem--noIcon]="sex.value === HadSex.NO"
                    [class.addData__otherSexItem--checked]="data.checked"
                    haptic
                    button
                  >
                    <ion-checkbox
                      [attr.uid]="sex.uid"
                      class="addData__otherSexCheckbox"
                      [checked]="data.checked"
                      (ionChange)="onSexChange($any($event), sex)"
                      [attr.aria-label]="sex.title | tr"
                    />

                    @if (sex.icon) {
                      <ion-icon slot="start" [src]="sex.icon" aria-hidden="true" />
                    }

                    <div class="addData__otherSexTitle">
                      {{ sex.title | tr }}
                    </div>
                  </ion-item>
                }
              }
            </ion-item-group>
          </ion-col>
        </ion-row>
      }

      <!-- LH test -->
      @if (config.lhSegments; as lhSegments) {
        <ion-row class="animated">
          <ion-col>
            <h3
              class="addData__form__subtitle"
              [class.addData__form__subtitle--clearblue]="config.appId === AppId.CLEARBLUE"
            >
              @if (config.appId === AppId.CLEARBLUE) {
                <ion-icon class="clearblue__logo" [src]="ICON.CLEARBLUE_LOGO" />
              }

              {{ 'txt-ovulation-test-lh' | tr }}
            </h3>

            <app-super-segment
              [ready]="ready"
              [items]="lhSegments"
              [value]="{ value: entry.lhTest }"
              [color]="ColorBySegment.LH_TEST"
              [prefixIcon]="entry.lhTestImageIds?.length ? ICON.EYE_ACTIVE : undefined"
              [readonly]="!!entry.lhTestImageIds?.length"
              (click)="
                entry.lhTestImageIds?.length &&
                  onLhTestWithImageClick(entry.lhTestImageIds, entry.lhTest)
              "
              (valueChange)="onLhTestChange($event)"
              (specialButtonClick)="onSpecialButtonClick($event)"
            />
            <div
              [tour]="TourTooltip.APP_6_3"
              style="position: absolute; left: 50%"
              tooltipPosition="bottom"
            ></div>
          </ion-col>
        </ion-row>
      }

      <!-- Preg test -->
      @if (config.pregTestSegments; as pregTestSegments) {
        <ion-row class="animated">
          <ion-col>
            <h3 class="addData__form__subtitle">{{ 'txt-pregnancy-test' | tr }}</h3>

            <app-super-segment
              [ready]="ready"
              [items]="pregTestSegments"
              [value]="{ value: entry.pregTest }"
              [color]="ColorBySegment.PREG_TEST"
              (valueChange)="onPregTestChange($event)"
            />
          </ion-col>
        </ion-row>
      }

      @if (showScrollDownButton) {
        <div #scrollButtonContainer class="addData__buttonContainer animated">
          <hr />

          <ion-button
            shape="round"
            size="small"
            class="addData__downButton"
            [class.addData__downButton--rotated]="downButtonRotated"
            (click)="scrollToBottom()"
            color="pink"
            [attr.aria-label]="'txt-scroll-down' | tr"
          >
            <ion-icon slot="icon-only" [src]="ICON.ARROW_DOWN" aria-hidden="true" />
          </ion-button>
        </div>
      }

      @if (showAllTrackers) {
        <span
          [@fadeAnimationSequence]
          (@fadeAnimationSequence.done)="fadeAnimationDone($any($event))"
        >
          <!-- Cervical Mucus -->
          @if (config.cmQuantitySegments || config.cmConsistencySegments) {
            <ion-row class="animated">
              <ion-col>
                <h3 class="addData__form__subtitle">
                  {{
                    (config.goal === Goal.PREGNANT
                      ? 'txt-cervical-mucus-follow'
                      : 'txt-cervical-mucus'
                    ) | tr
                  }}
                </h3>

                @if (config.cmQuantitySegments; as cervicalMucusQuantitySegments) {
                  <app-super-segment
                    #cmQuantity
                    class="addData__supersegment--inline"
                    [ready]="ready"
                    [items]="cervicalMucusQuantitySegments"
                    [value]="
                      config.cmConsistencySegments
                        ? {
                            value: entry.cervicalMucusQuantity
                              ? SuperSegmentSpecialButton.NO_VALUE
                              : undefined,
                            option: entry.cervicalMucusQuantity,
                          }
                        : {
                            value: entry.cervicalMucusQuantity,
                          }
                    "
                    [color]="ColorBySegment.CERVICAL_MUCUS"
                    (valueChange)="onCervicalMucusQuantityChange($event)"
                  />
                }

                @if (config.cmConsistencySegments; as cervicalMucusConsistencySegments) {
                  <app-super-segment
                    class="addData__supersegment--inline"
                    [ready]="ready"
                    [items]="cervicalMucusConsistencySegments"
                    [value]="{
                      value: entry.cervicalMucusConsistency
                        ? SuperSegmentSpecialButton.NO_VALUE
                        : undefined,
                      option: entry.cervicalMucusConsistency,
                    }"
                    [color]="ColorBySegment.CERVICAL_MUCUS"
                    (valueChange)="onCervicalMucusConsistencyChange($event)"
                    [@segmentAnimation]="cervicalMucusConsistencySegments.length ? 'show' : 'hide'"
                    (@segmentAnimation.done)="updateCmQuantity($event)"
                  />
                }
              </ion-col>
            </ion-row>
          }

          <!-- Libido -->
          @if (config.libidoSegments; as libidoSegments) {
            <ion-row class="animated">
              <ion-col>
                <h3 class="addData__form__subtitle">{{ 'txt-sex-drive' | tr }}</h3>

                <app-super-segment
                  [ready]="ready"
                  [items]="libidoSegments"
                  [value]="{
                    value: entry.libido,
                  }"
                  [color]="ColorBySegment.SEX"
                  (valueChange)="onLibidoChange($event)"
                />
              </ion-col>
            </ion-row>
          }

          <!-- Skin -->
          @if (config.skinFlags; as skinFlags) {
            <ion-row class="animated">
              <ion-col>
                <h3 class="addData__form__subtitle">{{ 'txt-skin' | tr }}</h3>

                <ion-item-group class="addData__skin">
                  @for (skin of skinFlags; track $index) {
                    <app-data-flag-with-intensity-toggle
                      [dataFlag]="skin"
                      dataFlagType="skin"
                      [checked]="isDataFlagChecked(skin)"
                      [dataIntensity]="dataFlagIntensityValue(skin)"
                      (dataFlagChange)="onDataFlagChange($event, skin)"
                      (scrollLock)="scrollLock.emit($event)"
                    />
                  }
                </ion-item-group>
              </ion-col>
            </ion-row>
          }

          <!-- Sleep -->
          @if (config.sleepSegments; as sleepSegments) {
            <ion-row class="animated">
              <ion-col>
                <h3 class="addData__form__subtitle">{{ 'txt-sleep' | tr }}</h3>

                <app-super-segment
                  [ready]="ready"
                  [items]="sleepSegments"
                  [value]="{
                    value: entry.sleep,
                  }"
                  [color]="ColorBySegment.SLEEP"
                  (valueChange)="onSleepChange($event)"
                />
              </ion-col>
            </ion-row>
          }

          <!-- Pain -->
          @if (config.painFlags; as painFlags) {
            <ion-row id="painSegment" class="animated">
              <ion-col>
                <h3 class="addData__form__subtitle">{{ 'txt-pain-symptoms' | tr }}</h3>

                <ion-item-group class="addData__pain">
                  @for (pain of painFlags; track $index; let i = $index) {
                    @if (i === 0) {
                      <div [tour]="TourTooltip.APP_7_1" style="position: absolute; left: 50%"></div>
                    }

                    <app-data-flag-with-intensity-toggle
                      [dataFlag]="pain"
                      dataFlagType="pain"
                      [checked]="isDataFlagChecked(pain)"
                      [dataIntensity]="dataFlagIntensityValue(pain)"
                      (dataFlagChange)="onDataFlagChange($event, pain)"
                      (scrollLock)="scrollLock.emit($event)"
                    />
                  }
                </ion-item-group>
              </ion-col>
            </ion-row>
          }

          <!-- Mood -->
          @if (config.moodFlags; as moods) {
            <ion-row id="moods" class="animated">
              <ion-col class="addData__col--noPadding">
                <div [tour]="TourTooltip.RECOVERY_4_1" style="position: absolute; left: 50%"></div>

                <h3 class="addData__form__subtitle">{{ 'txt-feeling' | tr }}</h3>

                <ion-item-group class="addData__moods">
                  @for (mood of moods; track $index; let i = $index) {
                    <ion-item
                      [attr.uid]="'addData__mood__' + DataFlag[mood]"
                      color="clear"
                      lines="none"
                      class="ion-no-padding addData__moodItem"
                      haptic
                      [style.order]="i"
                    >
                      @if ({ checked: isDataFlagChecked(mood) }; as data) {
                        <div class="addData__dataFlagContainer">
                          @if (i === 6) {
                            <div
                              [tour]="TourTooltip.APP_7_2"
                              style="position: absolute; left: 50%"
                            ></div>
                          }
                          <ion-checkbox
                            [id]="'addData__mood__' + DataFlag[mood]"
                            class="addData__dataFlagCheckbox"
                            [checked]="data.checked"
                            (ionChange)="onMoodChange($any($event), mood, i)"
                            [attr.aria-label]="'txt-dataflag--' + DataFlag[mood] | tr"
                          />

                          <ion-icon
                            class="addData__moodIcon"
                            [class.addData__moodIcon--checked]="data.checked"
                            [src]="ICON_BY_DATAFLAG[mood]"
                            [ngClass]="'addData__moodIcon--' + DataFlag[mood]"
                            aria-hidden="true"
                          />

                          <div class="addData__dataFlagTitle ion-text-wrap">
                            {{ 'txt-dataflag--' + DataFlag[mood] | tr }}
                          </div>
                        </div>
                      }
                    </ion-item>
                  }
                </ion-item-group>
              </ion-col>
            </ion-row>
          }

          <!-- Notes -->
          <ion-row class="animated">
            <ion-col>
              <h3 class="addData__form__subtitle">{{ 'txt-notes' | tr }}</h3>

              <app-notes
                class="ion-text-left"
                [notes]="form.controls['notes'].value"
                [placeholder]="'add-data-notes-placeholder' | tr"
                (valueChange)="onNotesChange($event)"
              />
            </ion-col>
          </ion-row>
        </span>
      }
    </ion-grid>
  </div>
}
