import { MixPanelService } from '@app/analytics/mixpanel.service'
import { appVer } from '@app/cnst'
import { isNativeApp } from '@app/cnst/userDevice.cnst'
import { AdminService } from '@app/srv/admin.service'
import { DeviceService } from '@app/srv/device.service'
import { di } from '@app/srv/di.service'
import { forceExperimentPrefix } from '@app/srv/experiment.service'
import { storageService } from '@app/srv/storage.service'
import { topbar } from '@naturalcycles/frontend-lib'
import {
  _filterNullishValues,
  AppError,
  getFetcher,
  HttpRequestError,
  IsoDateString,
  localTime,
  NumberOfMinutes,
  SemVerString,
  UnixTimestampNumber,
} from '@naturalcycles/js-lib'
import { BackendResponseFMResp, backendResponseReviver, NO_AB, OS } from '@naturalcycles/shared'
import { isE2e } from '@src/environments/env.util'
import { env } from '@src/environments/environment'
import { sessionSigningService } from './sessionSigning.service'
import { dispatch, getState } from './store.service'
import { tr } from './translation.util'

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
const utcOffset = localTime.now().getUTCOffsetMinutes()
const e2e = isE2e ? '1' : undefined
const e2eCountry = localStorage.getItem('e2eCountry') || undefined
const authorization = localStorage.getItem('e2eAuthHeader') || undefined

export interface ApiHeaders {
  ts: UnixTimestampNumber
  utcOffset: NumberOfMinutes
  tz: string
  /**
   * local "today" of the user
   */
  today: IsoDateString
  sessionId?: string
  distinctId?: string
  deviceId?: string
  'X-User-Country'?: string
  'X-Forced-Experiments': string
  appVer: SemVerString
  /**
   * IOS or ANDROID.
   * `undefined` if it cannot be detected (for some reason).
   */
  os: OS | undefined
  /**
   * Version of the OS (iOS or Android), e.g `17.4.1`
   * `undefined` if it cannot be detected (for some reason).
   */
  osVer: SemVerString | undefined
  deviceUuid: string
  /**
   * We are a "Natively packaged" app (not a Web app)
   * Previously known as "cordova"
   */
  native?: boolean
  'x-admin-token'?: string
  e2e?: '1'
  authorization?: string
  signature?: string
}

export const api = getFetcher({
  baseUrl: env.apiUrl,
  jsonReviver: backendResponseReviver,
  credentials: 'include',
  // Let e2e have a long timeout to reduce flakiness,
  // but shorter timeout in Production (non-e2e environment)
  timeoutSeconds: isE2e ? 120 : 30,
})
  .onBeforeRequest(async req => {
    const headers = await getExtraHeaders()
    Object.assign(req.init.headers, headers)

    if (!env.prod && !env.test) {
      topbar.show()
    }
  })
  .onAfterResponse(res => {
    if (res.ok && res.body && res.req.responseType === 'json' && isBackendResponseResp(res.body)) {
      processBackendResponse(res.body)
    }

    if (!env.prod && !env.test) {
      topbar.hide()
    }

    // transport error
    if (res.err instanceof HttpRequestError && !res.err.data.responseStatusCode) {
      throw new AppError(tr('txt-connection-error'), {
        userFriendly: true,
      })
    }
  })

export function processBackendResponse(body: any): void {
  if (!isBackendResponseResp(body)) return

  const { backendResponse: br } = body

  dispatch('onBackendResponse', br)

  if (br.uf) {
    dispatch('setLastSynchronized', localTime.now().toPretty())
  }
}

export function isBackendResponseResp(obj: any): obj is BackendResponseFMResp {
  return obj && typeof obj === 'object' && typeof obj['backendResponse'] === 'object'
}

export async function getExtraHeaders(): Promise<ApiHeaders> {
  const now = localTime.now()
  const { sessionId, userDevice } = getState()
  const ts = now.unix
  const signature = await sessionSigningService.sign(sessionId, ts)

  const headers: ApiHeaders = {
    appVer,
    ts,
    utcOffset,
    tz,
    today: now.toISODate(),
    sessionId,
    'X-User-Country': e2eCountry,
    'X-Forced-Experiments': getForcedExperimentsHeader(),
    os: di.get(DeviceService).getOS(),
    osVer: userDevice.version,
    deviceUuid: userDevice.uuid,
    distinctId: di.get(MixPanelService).distinctId,
    deviceId: di.get(MixPanelService).deviceId,
    native: isNativeApp || undefined,
    'x-admin-token': di.get(AdminService).getAdminToken(),
    e2e,
    authorization,
    signature,
  }

  return _filterNullishValues(headers)
}

export function getForcedExperimentsHeader(): string {
  return storageService
    .keys()
    .filter(key => key.startsWith(forceExperimentPrefix) || key === NO_AB)
    .map(key => {
      if (key === NO_AB) return `${NO_AB}=${storageService.get(key)}`
      const experimentId = key.split(forceExperimentPrefix)[1]
      return `${experimentId}=${storageService.get(key)}`
    })
    .join(',')
}
