<ion-header>
  <app-quiz-header />
</ion-header>

<ion-content>
  <div class="infoPageContent">
    <img
      width="343"
      height="343"
      [src]="baseImgLink + 'shared/learn_about_your_sex_drive.svg'"
      [alt]="'txt-sex-drive' | tr"
    />
  </div>
</ion-content>

<ion-footer>
  <div class="infoPageFooter">
    <h1 class="infoPageFooter__title">
      {{ 'txt-quiz-learn-about-sex' | tr }}
    </h1>
    <ion-button
      color="purple"
      expand="block"
      shape="round"
      [attr.aria-label]="'txt-quiz-next' | tr"
      [attr.uid]="page + '__footerButton'"
      (click)="nextPage()"
    >
      <ion-label>
        {{ 'txt-quiz-next' | tr }}
      </ion-label>
    </ion-button>
  </div>
</ion-footer>
