// only used by AB270
// If rolled out, could probably be moved to `ui`

import { ProductKey } from '@naturalcycles/shared'
import { Action, Reducer } from 'redux'

type State = ProductKey | null

const initialState: State = null

const actions: any = {
  setPendingMeasuringDevice(_state: State, action: { type: string; payload: ProductKey }): State {
    return action.payload
  },
}

export const pendingMeasuringDeviceReducer: Reducer<State> = (
  // biome-ignore lint/style/useDefaultParameterLast: ok
  state = initialState,
  action: Action,
) => {
  if (actions[action.type]) return actions[action.type](state, action)
  return state
}
