<div></div>

<div class="container">
  <div class="square">
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      aria-hidden="true"
      alt=""
    />

    <div class="content">
      <ng-content />
    </div>
  </div>
</div>

<div></div>
