import { inject, Injectable, NgZone } from '@angular/core'
import { tryCatch } from '@app/decorators/tryCatch.decorator'
import { BluetoothPluginMockState } from '@app/mocks/bluetoothPluginMock.reducer'
import { UserAchievements } from '@app/reducers/achievements.reducer'
import { AddDataState } from '@app/reducers/addData.reducer'
import { GlossaryState } from '@app/reducers/glossary.reducer'
import { GuideState } from '@app/reducers/guide.reducer'
import { HardwareDevice } from '@app/reducers/hardwareDevice.reducer'
import { HKDeviceFM } from '@app/reducers/hkDevice.reducer'
import { MeasureStreakState } from '@app/reducers/measureStreak.reducer'
import { MessagesState } from '@app/reducers/messages.reducer'
import { Notifications } from '@app/reducers/notification.reducer'
import { OfflineEvents } from '@app/reducers/offlineEvents.reducer'
import { QuizState } from '@app/reducers/quiz.reducer'
import { QuizzesState } from '@app/reducers/quizzes.reducer'
import { rootReducer } from '@app/reducers/root.reducer'
import { UI } from '@app/reducers/ui.reducer'
import { UserDeviceAuth } from '@app/reducers/userDeviceAuth.reducer'
import { UserSettings } from '@app/reducers/userSettings.reducer'
import { storeServiceInitialized } from '@app/srv/milestones'
import { storage3Service } from '@app/srv/storage3/storage3.service'
import { distinctUntilDeeplyChanged } from '@app/util/distinctUntilDeeplyChanged'
import { logUtil } from '@app/util/log.util'
import { _omit, _pick, StringMap } from '@naturalcycles/js-lib'
import {
  AccountDataFM,
  AccountTM,
  Blog,
  CartState,
  ExperimentState,
  FriendReferral,
  FWVersion,
  Hardware,
  HardwareId,
  Order,
  OuraState,
  PartnerAccountFM,
  PaymentState,
  ProductKey,
  ProductState,
  PublicAndPrivateKeyTransport,
  RemoteConfigApp,
  ShippingItemFM,
  SubscriptionStateFM,
  UFRaw,
  UnplannedPregnancyDataInput,
  UserDeviceFM,
  UserFertility,
  userFertilitySharedUtil,
  UserLocale,
  WidgetData,
} from '@naturalcycles/shared'
import { createStore, Store, UnknownAction, Unsubscribe } from 'redux'
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators'
import { AppSettingsFM } from './appSettings.cnst'

export interface GlobalState {
  appVer: string
  payment: PaymentState
  product: ProductState
  order: Order
  cart: CartState
  userLocale: UserLocale
  account: AccountTM
  accountData: AccountDataFM
  counter: number
  sessionId: string
  remoteConfig: RemoteConfigApp
  // pricing: Pricing
  userFertility: UserFertility
  ufRaw: string
  ui: UI
  flowData: StringMap<any>
  notifications: Notifications
  userDevice: UserDeviceFM
  userDeviceAuth: UserDeviceAuth | null
  userSettings: UserSettings
  messages: MessagesState
  addData: AddDataState
  achievements: UserAchievements
  offlineEvents: OfflineEvents
  experiment: ExperimentState
  friendReferral: FriendReferral
  subscriptions: SubscriptionStateFM
  glossary: GlossaryState
  guides: GuideState
  quizzes: QuizzesState
  measureStreak: MeasureStreakState
  blog: Blog[]
  oura: OuraState | null
  hwDevice: HardwareDevice | null
  hkDevice: HKDeviceFM | null
  hwChanges: StringMap<HardwareId>
  latestHWDeviceFWVersion: FWVersion | null
  partners: PartnerAccountFM[]
  partnerAccount: PartnerAccountFM | null
  appSettings: AppSettingsFM | null
  unplannedPregnancy: UnplannedPregnancyDataInput | null
  availableHardwares: Hardware[]
  quiz: QuizState
  widgetData: WidgetData
  replacementShippingItem: ShippingItemFM | null
  sessionEncryptionKeys: PublicAndPrivateKeyTransport | null

  /** ‼️ IT IS USED ONLY WHEN BLUETOOTH MOCK IS ENABLED ‼️ */
  bluetoothPluginMock: BluetoothPluginMockState

  /**
   * AB 270
   * Used to store the selection made on the measuring device page
   * before the user has created an account.
   */
  pendingMeasuringDeviceSelection: ProductKey | null

  /**
   * AB 272
   * Used to store the perimenopause selection on quiz fertility goal page
   * to check if the user needs to select a new goal.
   */
  perimenopauseBackdoor: {
    hasSelectedPerimenopause: boolean
    shouldHidePerimenopause: boolean
  }
}

export interface DeviceUserSettings {
  userSettings: UserSettings
  notifications: Notifications
}

export const USER_SETTINGS = 'userSettings'
export const USER_DEVICE = 'userDevice'
const STATE = 'state'

export type Selector<StateType, SelectedType> =
  | FunctionSelector<StateType, SelectedType>
  | ArraySelector
export type FunctionSelector<StateType, SelectedType> = (state: StateType) => SelectedType
export type ArraySelector = (string | number)[]

// Reference to the initialized global state
// It's extracted from the StoreService to power the @select decorator
const state$ = new BehaviorSubject<GlobalState>(undefined as any)

let storeService: StoreService

// shortcut
export function getState(): GlobalState {
  return storeService.getState()
}

// shortcut
export function dispatch(type: string, payload?: any): void {
  storeService.dispatch(type, payload)
}

@Injectable({ providedIn: 'root' })
export class StoreService {
  private ngZone = inject(NgZone)

  constructor() {
    storeService = this
  }

  private reduxStore!: Store<GlobalState>

  /**
   * List of properties of STATE to be persisted (everything else will NOT be persisted)
   */
  private static STATE_PERSIST: (keyof GlobalState)[] = [
    'appVer',
    'counter',
    'remoteConfig',
    'userLocale',
    // 'userFertility', persisted in raw mode as localStorage.ufRaw (inside userFertilityReducer), for optimization reasons
    'account',
    'accountData',
    'sessionId',
    USER_DEVICE,
    'order',
    'addData',
    'messages',
    'cart',
    'achievements',
    'product',
    'offlineEvents',
    'experiment',
    'friendReferral',
    'subscriptions',
    'measureStreak',
    'blog',
    'quizzes',
    'hwDevice',
    'hkDevice',
    'hwChanges',
    'partners',
    'partnerAccount',
    'appSettings',
    // 'glossary', persisted in separate storage3 file
    // 'guides', persisted in separate storage3 file,
    'quiz',
    'widgetData',
    'replacementShippingItem',
    'sessionEncryptionKeys',

    // AB 270
    'pendingMeasuringDeviceSelection',

    // AB 272
    'perimenopauseBackdoor',
  ]

  /**
   * List of properties of STATE to be persisted on device even after logout
   * TODO: REMOVE THIS
   */
  private static STATE_DEVICE_PERSIST: (keyof GlobalState)[] = ['notifications', USER_SETTINGS]

  public persistenceEnabled = true

  async init(): Promise<void> {
    const initialState = await this.loadInitialState()

    this.reduxStore = createStore(rootReducer, initialState)
    state$.next(this.reduxStore.getState())
    this.reduxStore.subscribe(() => state$.next(this.reduxStore.getState()))

    // Persisting state to LocalStorage
    state$
      .pipe(
        debounceTime(1000),
        tap(state => void this.storeUserDeviceSettings(state)),
        map((state: GlobalState) => _pick(state, StoreService.STATE_PERSIST)),
        distinctUntilDeeplyChanged(),
        mergeMap(async stateToPersist => {
          if (!this.persistenceEnabled) return

          await storage3Service.setItem(STATE, stateToPersist).catch(err => {
            logUtil.log('Error storing state')
            logUtil.error(err)
          })
        }, 1),
      )
      .subscribe()

    storeServiceInitialized.resolve()

    // for debugging
    // ;(globalThis as any).redux = this.reduxStore
  }

  public async updateStateFromStorage(): Promise<void> {
    const initialState = await this.loadInitialState()

    if (!initialState) return

    dispatch('extendUf', initialState.userFertility)
    dispatch('onBackendResponse', {
      ..._omit(initialState, ['appSettings', 'userFertility']), // appSettings is stored in another format than backendResponse
    })
  }

  private async storeUserDeviceSettings(state: GlobalState): Promise<void> {
    if (!this.persistenceEnabled) return

    if (!state.account.id) return

    const partialState = {
      [state.account.id]: _pick(state, StoreService.STATE_DEVICE_PERSIST) as Partial<GlobalState>,
    }

    const current = await storage3Service.getItem<{ id: DeviceUserSettings }>(USER_SETTINGS, true)

    void storage3Service.setItem(USER_SETTINGS, { ...current, ...partialState }).catch(err => {
      logUtil.log('Error storing device settings')
      logUtil.error(err)
    })
  }

  /**
   * It RESETS the state if overrideStats is passed.
   * Otherwise just persists.
   * It disables persistence.
   */
  public async persistStateNowAndDisablePersistence(
    overrideState?: Partial<GlobalState>,
  ): Promise<void> {
    // To avoid race condition we need to disable persistence
    this.persistenceEnabled = false

    let state: Partial<GlobalState> = this.getState()

    if (overrideState) {
      state = {
        ...rootReducer(undefined as any, { type: '__init' }),
        ...overrideState,
      }
    }

    const stateToPersist = _pick(state, StoreService.STATE_PERSIST as any)
    logUtil.log('state > LS (persistStateNowAndDisablePersistence)')
    await storage3Service.setItem(STATE, stateToPersist)
  }

  public async persistStateNow(): Promise<void> {
    const state: Partial<GlobalState> = this.getState()

    const stateToPersist = _pick(state, StoreService.STATE_PERSIST as any)
    logUtil.log('state > LS (persistStateNow)')
    await storage3Service.setItem(STATE, stateToPersist)
  }

  @tryCatch()
  private async loadInitialState(): Promise<GlobalState | undefined> {
    const [initialState, ufRaw, glossary, guides, deviceUserSettings] = await Promise.all([
      storage3Service.getItem<GlobalState>(STATE, true),
      storage3Service.getItem<UFRaw>('ufRaw', true, true),
      storage3Service.getItem<GlossaryState>('glossary', true, true),
      storage3Service.getItem<GuideState>('guides', true, true),
      storage3Service.getItem<StringMap<DeviceUserSettings>>(USER_SETTINGS, true, true),
    ])

    if (initialState) {
      if (glossary) {
        Object.assign(initialState, { glossary })
      }

      if (guides) {
        Object.assign(initialState, { guides })
      }

      if (ufRaw) {
        // process ufRaw into userFertility
        Object.assign(initialState, {
          userFertility: userFertilitySharedUtil.parseUFRaw(ufRaw),
        })
      }

      if (deviceUserSettings?.[initialState.account.id]) {
        const { notifications, userSettings } = deviceUserSettings[initialState.account.id] || {}

        Object.assign(initialState, { notifications, userSettings })
      }
    }

    return initialState
  }

  public getState(): GlobalState {
    return this.reduxStore.getState()
  }

  public dispatch(type: string, payload?: any): void {
    const action: UnknownAction = {
      type,
      payload,
    }

    if (NgZone.isInAngularZone()) {
      this.reduxStore.dispatch(action)
    } else {
      this.ngZone.run(() => this.reduxStore.dispatch(action))
    }
  }

  public subscribe(listener: () => any, emitCurrentValue = false): Unsubscribe {
    if (emitCurrentValue) {
      listener()
    }
    return this.reduxStore.subscribe(listener)
  }

  public select<S>(selector: FunctionSelector<GlobalState, S>): Observable<S> {
    return state$.pipe(
      distinctUntilChanged(),
      map(state => selector(state)),
      distinctUntilChanged(),
    )
  }
}

function selectorToFunctionSelector<StateType, SelectedType>(
  s: Selector<StateType, SelectedType>,
): FunctionSelector<StateType, SelectedType> {
  if (typeof s === 'function') return s

  // eslint-disable-next-line unicorn/no-array-reduce
  return (state: StateType) => s.reduce((acc: any, key) => acc?.[key], state)
}

/**
 * @select decorator
 */
export function select(
  selector: Selector<GlobalState, any>,
  transformer?: OperatorFunction<any, any>,
): PropertyDecorator {
  return function selectDecorator(target: any, key): void {
    const functionSelector = selectorToFunctionSelector(selector)

    function getter(this: any): Observable<any> {
      if (transformer) {
        return state$.pipe(
          distinctUntilChanged(),
          map(functionSelector),
          transformer,
          distinctUntilChanged(),
        )
      }

      return state$.pipe(distinctUntilChanged(), map(functionSelector), distinctUntilChanged())
    }

    // Replace decorated property with a getter that returns the observable.
    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        enumerable: true,
        configurable: true,
      })
    }
  }
}

/**
 * Modern type-safe functional selector, to replace `@select` decorator.
 *
 * It'll be renamed to just `select` after `@select` is gone in the future.
 */
export function select2<T>(selector: FunctionSelector<GlobalState, T>): Observable<T> {
  return state$.pipe(distinctUntilChanged(), map(selector), distinctUntilChanged())
}
