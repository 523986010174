import { Directive, inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NavController } from '@ionic/angular'
import { GoalBreakpointOption } from '@naturalcycles/shared'
import { EVENT } from '@src/app/analytics/analytics.cnst'
import { AnalyticsService } from '@src/app/analytics/analytics.service'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { NavigationParams } from '@src/app/cnst/nav.params.cnst'
import { baseImgLink, goalMap, quizParentSlug } from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@src/app/decorators/decorators'
import { BasePage } from '@src/app/pages/base.page'
import { AccountService } from '@src/app/srv/account.service'
import { QuizService } from '@src/app/srv/quiz/quiz.service'
import { getState } from '@src/app/srv/store.service'
import { shouldVerifyEmailOnWeb } from '@src/app/util/emailVerification.util'
import { isE2e } from '@src/environments/env.util'
import { AuthPageMode } from '@src/typings/auth'

@Directive()
export abstract class QuizBasePage extends BasePage implements OnInit {
  public className = 'QuizBasePage'
  public baseImgLink = baseImgLink
  public page: QuizPage | null = null
  public selfHidingModalTimeout = isE2e ? 1000 : 2000
  public analyticsEventName = EVENT.QUIZ_ANSWER
  public quizService = inject(QuizService)
  public analyticsService = inject(AnalyticsService)
  public router = inject(Router)
  public route = inject(ActivatedRoute)

  private accountService = inject(AccountService)
  private navController = inject(NavController)

  public ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.page = data['page'] as QuizPage
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async navigateForwardInQuiz(): Promise<void> {
    const { quiz, sessionId } = getState()

    if (!this.page) {
      console.warn('[QUIZ dev] Route data missing')
      return
    }

    const navigateTo = this.quizService.getEngine().getNextPageInFlow(this.page, quiz.flow)

    // If the only remaining page is this one, we consider the quiz completed
    const quizCompleted = navigateTo === this.page
    if (!quizCompleted) {
      await super.navigateForward(`${quizParentSlug}/${navigateTo}`)
      return
    }
    /**
     * For users who have already created an account at this stage (signup on login page with social auth or AB272)
     * we need to patch the goal as it is normally done on registration
     */
    if (sessionId) {
      const goal = goalMap[quiz.data?.fertilityGoal || GoalBreakpointOption.prevent]
      await this.accountService.patch({ goal })
    }

    if (shouldVerifyEmailOnWeb()) {
      await this.navigateForward(ROUTES.VerifyEmailPage)
      return
    }

    if (sessionId) {
      await this.navController.navigateRoot(ROUTES.SignupMeasuringDevicePage)
      return
    }

    await this.navigateForward(
      ROUTES.AuthPage,
      {},
      {
        state: {
          [NavigationParams.AUTH_PAGE_MODE]: AuthPageMode.Signup,
        },
      },
    )
  }
}
