import { inject, Injectable } from '@angular/core'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { isHardwareSupportedPlatform } from '@app/cnst/hardware.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { api } from '@app/srv/api.service'
import { getState, select2 } from '@app/srv/store.service'
import { logUtil } from '@app/util/log.util'
import { BackendResponseFMResp, HardwareId, OuraState } from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { firstValueFrom, Subscription } from 'rxjs'
import { combineLatestWith, filter, map, take } from 'rxjs/operators'
import { LINK } from '../cnst/links.cnst'
import { isNativeApp } from '../cnst/userDevice.cnst'
import { CtaModal, CtaModalInput } from '../modals/cta-modal/cta.modal'
import { OuraAuthSuccessModal } from '../pages/oura/oura-auth-success/oura-auth-success.modal'
import { EventService } from './event.service'
import { InAppBrowserService } from './inappbrowser.service'
import { PopupController, Priority } from './popup.controller'

const TRY_AGAIN = 'try-again'

@Injectable({ providedIn: 'root' })
export class OuraService {
  private analyticsService = inject(AnalyticsService)
  private popupController = inject(PopupController)
  private eventService = inject(EventService)
  private inAppBrowserService = inject(InAppBrowserService)

  private items$ = select2(s => s.product.items)
  public hwId$ = select2(s => s.account.hwId)
  public ouraDevices$ = select2(s => s.account.ouraDevices)
  public ouraAuthorized$ = select2(s => s.oura?.authorized)

  public hasOura$ = this.hwId$.pipe(map(hwId => hwId === HardwareId.OURA))

  public isOuraRingAvailable$ = this.items$.pipe(
    map(
      products =>
        products.some(product => product.hwId === HardwareId.OURA) && isHardwareSupportedPlatform,
    ),
  )

  public isOuraRingPromoAvailable$ = this.hasOura$.pipe(
    combineLatestWith(this.isOuraRingAvailable$),
    map(([hasOura, isOuraRingAvailable]) => !hasOura && isOuraRingAvailable),
  )

  public showOuraRenewPermissionsBanner$ = this.ouraDevices$.pipe(
    combineLatestWith(this.hwId$, this.ouraAuthorized$),
    map(([ouraDevices, hwId, authorized]) => {
      if (hwId !== HardwareId.OURA) return false
      if (authorized && !ouraDevices?.length) return true
      return false
    }),
  )
  private ouraDevicesSubscription?: Subscription

  public async openOuraAuth(): Promise<void> {
    if (this.ouraDevicesSubscription) {
      this.ouraDevicesSubscription.unsubscribe()
      this.ouraDevicesSubscription = undefined
    }

    const ouraState = await this.auth()

    const { authorized } = ouraState || {}

    if (!authorized) {
      void this.openAuthFailed()

      return
    }

    this.ouraDevicesSubscription = this.ouraDevices$
      .pipe(
        filter(ouraDevices => !!ouraDevices?.length),
        take(1),
      )
      .subscribe(ouraDevices => {
        if (ouraDevices?.length) {
          void this.openAuthSuccessModal(true)
        }
      })
  }

  public async auth(): Promise<OuraState | undefined> {
    const token = await this.getOuraAuthToken()

    const url = `${env.prod ? LINK.OURA_AUTH : LINK.OURA_AUTH_INTERNAL}&state=${token}`

    void this.inAppBrowserService.open(url)

    await this.authCallback()

    return await this.getOuraState()
  }

  private async authCallback(): Promise<void> {
    if (isNativeApp) {
      await firstValueFrom(this.eventService.inAppBrowserClosed$)
    } else {
      await firstValueFrom(this.eventService.onResume$)
    }
  }

  private async getOuraAuthToken(): Promise<string> {
    const { token } = await api.put<{ token: string }>(`oura/createAuthToken`)

    return token
  }

  public async openAuthFailed(): Promise<void> {
    const { tryAgain } = await this.openAuthFailedModal()

    if (tryAgain) void this.openOuraAuth()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
    messageKey: 'blocking-loader-oura-sync',
  })
  private async getOuraState(): Promise<OuraState | undefined> {
    return await api
      .get<BackendResponseFMResp>(`oura/state`, { timeoutSeconds: 60 }) // request can take long time for user with lots of data
      .then(r => r.backendResponse.oura)
      .catch(err => {
        logUtil.error(`Error getting Oura status: ${err}`)
        return { authorized: false } as OuraState
      })
  }

  public getDiscountLink(): string {
    const { account } = getState()
    const viewType = this.analyticsService.getViewType()
    return `${env.apiUrl}/oura/discountLink/${account.personalId}?source=${viewType}`
  }

  public async openAuthFailedModal(): Promise<{ tryAgain: boolean }> {
    const componentProps: CtaModalInput = {
      title: 'oura-onboarding-auth-fail-title',
      body: 'oura-onboarding-auth-fail-body',
      ctas: [
        {
          title: 'txt-try-again',
          id: TRY_AGAIN,
        },
        {
          title: 'txt-skip',
          id: 'skip',
          outline: true,
        },
      ],
    }

    const modal = await this.popupController.presentModal(
      {
        component: CtaModal,
        componentProps,
      },
      'modal-oura-auth-fail',
      Priority.IMMEDIATE,
    )

    const { data } = await modal.onDidDismiss()

    return {
      tryAgain: data === TRY_AGAIN,
    }
  }

  public async openAuthSuccessModal(confetti?: boolean): Promise<void> {
    const modal = await this.popupController.presentModal(
      {
        component: OuraAuthSuccessModal,
        componentProps: {
          confetti,
        },
      },
      'modal-oura-auth-success',
      Priority.IMMEDIATE,
    )

    await modal.onDidDismiss()
  }

  public async disconnectOuraAccount(): Promise<OuraState | undefined> {
    const { backendResponse } = await api.put<BackendResponseFMResp>(`oura/disconnect`)

    return backendResponse.oura
  }
}
