@if (data) {
  <ul>
    @for (key of objectKeys; track key) {
      <li
        [class.collapsible]="isCollapsible[key]"
        [class.collapsed]="isCollapsed[key]"
        (click)="toggleCollapse($event, key)"
        tabindex="0"
      >
        <span class="key">{{ key }}</span>
        @if (!isCollapsible[key]) {
          <span class="value">{{ data[key] }}</span>
        }
        @if (isCollapsible[key] && !isCollapsed[key]) {
          <app-tree-layer [data]="data[key]" />
        }
      </li>
    }
  </ul>
}
