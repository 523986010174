import { Injectable } from '@angular/core'
import { _numberEnumValues, AnyEnum } from '@naturalcycles/js-lib'
import {
  BirthControlMethodBreakpointOption,
  BleedingChangesOption,
  CervicalMucusChangesOption,
  ConceiveTimingBreakpointOption,
  ConsideringPurchaseOption,
  CycleChangesBreakpointOption,
  EligibleAppleWatchBreakpointOption,
  EmotionsOption,
  HormonalBirthControlHowLongBreakpointOption,
  HormonalBirthControlUsageBreakpointOption,
  LibidoChangesOption,
  MedicalConditionsBreakpointOption,
  ProductKey,
  PurchaseNewWatchOption,
  QuizDataInput,
  QuizDataKey,
  QuizEngine,
  shortQuizGoalOptions,
  SideEffectsBreakpointOption,
  SkinChangesOption,
  SleepWithTrackerOption,
  TypeOfPainOption,
  WearWatchToBedOption,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import { ExtendedGoalBreakpointOption, ExtraGoalBreakpointOption } from '@src/ab272/ab272'
import {
  AppQuizFlow,
  Breakpoint,
  breakpointActions,
  commonQuizFlow,
  guardQuizData,
  QuizSelectOption,
  Section,
} from '@src/app/cnst/quiz.cnst'
import { QuizPage } from '@src/app/cnst/quiz-pages.cnst'
import { appInitDone } from '../milestones'
import { dispatch } from '../store.service'

export type BreakpointOption =
  | ExtendedGoalBreakpointOption
  | BirthControlMethodBreakpointOption
  | SideEffectsBreakpointOption
  | ConceiveTimingBreakpointOption
  | HormonalBirthControlUsageBreakpointOption
  | HormonalBirthControlHowLongBreakpointOption
  | MedicalConditionsBreakpointOption
  | CycleChangesBreakpointOption
  | WhichWearableBreakpointOption
  | EligibleAppleWatchBreakpointOption
  | SleepWithTrackerOption

export const cycleChangesStoreToOptions: Partial<
  Record<
    QuizDataKey,
    {
      name: string
      en: AnyEnum
    }
  >
> = {
  emotions: { name: 'EmotionsOption', en: EmotionsOption },
  typeOfPain: { name: 'TypeOfPainOption', en: TypeOfPainOption },
  skinChanges: { name: 'SkinChangesOption', en: SkinChangesOption },
  libidoChanges: { name: 'LibidoChangesOption', en: LibidoChangesOption },
  bleedingChanges: { name: 'BleedingChangesOption', en: BleedingChangesOption },
  cervicalMucusChanges: { name: 'CervicalMucusChangesOption', en: CervicalMucusChangesOption },
}

@Injectable({ providedIn: 'root' })
export class QuizService {
  private quizEngine = new QuizEngine<
    Breakpoint,
    BreakpointOption,
    QuizPage,
    Section,
    QuizDataInput
  >({
    breakpointActions,
    guardData: guardQuizData,
    initialFlow: commonQuizFlow,
  })

  public async init(): Promise<void> {
    await appInitDone
    dispatch('replaceQuizFlow', this.getEngine().getInitialFlow())
  }

  // Used to easily use another quiz engine for AB tests
  public getEngine(): QuizEngine<Breakpoint, BreakpointOption, QuizPage, Section, QuizDataInput> {
    return this.quizEngine
  }

  public getOptionsForQuestion(storeKey: QuizDataKey): QuizSelectOption[] {
    if (!cycleChangesStoreToOptions[storeKey]) {
      return []
    }

    const { name, en } = cycleChangesStoreToOptions[storeKey]!

    return _numberEnumValues(en).map((value: number | string) => {
      return {
        key: value as number,
        textKey: `txt-quiz-${name}--${en[value]}`,
      }
    })
  }

  public hasCompletedQuiz(data: QuizDataInput | null): boolean {
    return (
      !!data?.whichWearable ||
      !!(
        data?.fertilityGoal &&
        [...shortQuizGoalOptions, ExtraGoalBreakpointOption.perimenopause].includes(
          data.fertilityGoal,
        )
      )
    )
  }

  private hasPageInFlow(page: QuizPage, flow: AppQuizFlow): boolean {
    return this.getEngine().getSectionForPage(page, flow) !== undefined
  }

  public getRecommendedDevice(data: QuizDataInput | null, flow: AppQuizFlow): ProductKey {
    if (!data) {
      return ProductKey.BT_THERMOMETER
    }
    const {
      eligibleAppleWatch,
      purchaseNewWatch,
      consideringPurchase,
      whichWearable,
      sleepWithTracker,
      wearWatchToBed,
    } = data

    const canWearWatchToBed =
      [WearWatchToBedOption.dontKnow, WearWatchToBedOption.yes].includes(wearWatchToBed!) ||
      [SleepWithTrackerOption.yesWithBoth, SleepWithTrackerOption.yesWithWatch].includes(
        sleepWithTracker!,
      )

    const hasEligebleAppleWatch =
      eligibleAppleWatch === EligibleAppleWatchBreakpointOption.yes &&
      this.hasPageInFlow(QuizPage.eligibleAppleWatch, flow || [])

    const considersPurchasingAppleWatch =
      (consideringPurchase === ConsideringPurchaseOption.yesAppleWatch &&
        this.hasPageInFlow(QuizPage.consideringPurchase, flow || [])) ||
      (purchaseNewWatch === PurchaseNewWatchOption.yes &&
        this.hasPageInFlow(QuizPage.purchaseNewWatch, flow || []))

    if (canWearWatchToBed && (hasEligebleAppleWatch || considersPurchasingAppleWatch)) {
      return ProductKey.APPLE_WATCH
    }

    if (
      whichWearable?.includes(WhichWearableBreakpointOption.ouraRing) ||
      consideringPurchase === ConsideringPurchaseOption.yesOuraRing
    ) {
      return ProductKey.OURA_RING_DISCOUNT
    }

    return ProductKey.BT_THERMOMETER
  }
}

/*
Q: Why do some pages have URLs unique for both "prevent" and "plan" flows, while others have separate
/xxx-prevent" and "/xxx-plan" URLs even if they "look" the same?
A: Different URLs means that when the user lands on the page (Eg. by direct link) we know
   immediately his position in the flow, without the necessity to look into the state data to
   discern if user is in "prevent" or "plan".


Q: Why do we have "info-pages" which are shared between "prevent" and "plan" flows?
A: It seemed overkill to have many duplicates of the same page in the case of "info-pages". Since their
role is strictly presentational (no BreakpointAction needs to be processed), we allow them to be
shared between "prevent" and "plan" flows when needed.


Q: Can I expand the functionality?
A: Sure! As long as the service stays:
- free from side effects
- free from interaction with Angular features (store, route, etc)

This helps isolating the service so that it is more easily unit testable.
 */
