import { bluetoothPluginMockReducer } from '@app/mocks/bluetoothPluginMock.reducer'
import { GlobalState } from '@app/srv/store.service'
import { pendingMeasuringDeviceReducer } from '@src/ab270/pendingMeasuringDevice.reducer'
import { perimenopauseBackdoorReducer } from '@src/ab272/perimenopauseBackdoor.reducer'
import { AnyAction, combineReducers, Reducer } from 'redux'
import { accountReducer } from './account.reducer'
import { accountDataReducer } from './accountData.reducer'
import { achievementsReducer } from './achievements.reducer'
import { addDataReducer } from './addData.reducer'
import { appSettingsReducer } from './appSettings.reducer'
import { appVerReducer } from './appVer.reducer'
import { availableHardwaresReducer } from './availableHardwares.reducer'
import { blogReducer } from './blog.reducer'
import { cartReducer } from './cart.reducer'
import { experimentReducer } from './experiment.reducer'
import { flowDataReducer } from './flowData.reducer'
import { friendReferralReducer } from './friendReferral.reducer'
import { glossaryReducer } from './glossary.reducer'
import { guideReducer } from './guide.reducer'
import { hardwareDeviceReducer } from './hardwareDevice.reducer'
import { hkDeviceReducer } from './hkDevice.reducer'
import { hwChangesReducer } from './hwChanges.reducer'
import { latestHWDeviceFWVersionReducer } from './latestHWDeviceFWVersion.reducer'
import { measureStreakReducer } from './measureStreak.reducer'
import { messagesReducer } from './messages.reducer'
import { notificationReducer } from './notification.reducer'
import { offlineEventsReducer } from './offlineEvents.reducer'
import { orderReducer } from './order.reducer'
import { ouraReducer } from './oura.reducer'
import { partnerAccountReducer } from './partnerAccount.reducer'
import { partnersReducer } from './partners.reducer'
import { paymentReducer } from './payment.reducer'
import { productReducer } from './product.reducer'
import { quizReducer } from './quiz.reducer'
import { quizzesReducer } from './quizzes.reducer'
import { remoteConfigReducer } from './remoteConfig.reducer'
import { replacementShippingItemReducer } from './replacementShippingItem.reducer'
import { sessionReducer } from './session.reducer'
import { sessionEncryptionKeysReducer } from './sessionEncryptionKeys.reducer'
import { subscriptionReducer } from './subscription.reducer'
import { uiReducer } from './ui.reducer'
import { userDeviceReducer } from './userDevice.reducer'
import { userDeviceAuthReducer } from './userDeviceAuth.reducer'
import { userFertilityReducer } from './userFertility.reducer'
import { userLocaleReducer } from './userLocale.reducer'
import { userSettingsReducer } from './userSettings.reducer'
import { widgetDataReducer } from './widgetData.reducer'

const appReducer: Reducer<GlobalState, AnyAction> = combineReducers({
  appVer: appVerReducer,
  remoteConfig: remoteConfigReducer,
  product: productReducer,
  order: orderReducer,
  userLocale: userLocaleReducer,
  account: accountReducer,
  accountData: accountDataReducer,
  sessionId: sessionReducer,
  ui: uiReducer,
  flowData: flowDataReducer,
  userDevice: userDeviceReducer,
  userDeviceAuth: userDeviceAuthReducer,
  userSettings: userSettingsReducer,
  userFertility: userFertilityReducer,
  notifications: notificationReducer,
  addData: addDataReducer,
  messages: messagesReducer,
  cart: cartReducer,
  achievements: achievementsReducer,
  payment: paymentReducer,
  offlineEvents: offlineEventsReducer,
  experiment: experimentReducer,
  friendReferral: friendReferralReducer,
  subscriptions: subscriptionReducer,
  glossary: glossaryReducer,
  guides: guideReducer,
  quizzes: quizzesReducer,
  measureStreak: measureStreakReducer,
  blog: blogReducer,
  oura: ouraReducer,
  hwDevice: hardwareDeviceReducer,
  hwChanges: hwChangesReducer,
  hkDevice: hkDeviceReducer,
  latestHWDeviceFWVersion: latestHWDeviceFWVersionReducer,
  partners: partnersReducer,
  partnerAccount: partnerAccountReducer,
  appSettings: appSettingsReducer,
  availableHardwares: availableHardwaresReducer,
  quiz: quizReducer,
  widgetData: widgetDataReducer,
  replacementShippingItem: replacementShippingItemReducer,
  bluetoothPluginMock: bluetoothPluginMockReducer,
  sessionEncryptionKeys: sessionEncryptionKeysReducer,

  // AB270
  pendingMeasuringDeviceSelection: pendingMeasuringDeviceReducer,

  // AB272
  perimenopauseBackdoor: perimenopauseBackdoorReducer,
}) as any

export const rootReducer: Reducer<GlobalState, AnyAction> = (
  state: any, // todo: GlobalState,
  action: AnyAction,
) => {
  if (action.type === 'reset') {
    // Reset State to it's "initialState" (every downstream reducer will apply it)
    state = undefined as any

    localStorage.clear()
    clearCookies()
  }

  if (action.type === 'logout') {
    // resets everything but ui params
    state = { ui: state.ui }
    clearCookies()
  }

  return appReducer(state, action)
}

function clearCookies(): void {
  for (const pair of document.cookie.split(';')) {
    const [key] = pair.split('=')
    // eslint-disable-next-line unicorn/no-document-cookie
    document.cookie = `${key} =; expires = Thu, 01 Jan 1970 00:00:00 UTC`
  }
}
