import { Injectable } from '@angular/core'
import { PageGuard } from '@app/guards/page.guard'
import { isAb270Test2 } from '../ab270'

@Injectable({
  providedIn: 'root',
})
export class MeasuringDevicePageGuardAb270 extends PageGuard {
  className = 'MeasuringDevicePageGuardAb270'

  get requirements(): boolean[] {
    const requirements = [isAb270Test2() && !this.hasSession()]

    return requirements
  }
}
