import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { EVENT } from '@app/analytics/analytics.cnst'
import {
  addAnalyticsProps,
  AnalyticsService,
  getAnalyticsProps,
} from '@app/analytics/analytics.service'
import { fadeAnimation } from '@app/animations/fade'
import { verticalSlideAnimation } from '@app/animations/vertical-slide'
import { AdditionalInfoComponent } from '@app/cmp/add-data-additional-info/add-data-additional-info.component'
import { TemperatureStateClick } from '@app/cmp/temperature-display/temperature-display.component'
import {
  AddDataConfig,
  AddDataSource,
  OURA_SNACKBAR,
  TemperatureDataState,
  TemperatureState,
} from '@app/cnst/add-data.cnst'
import { HW_CONFIG_BY_HWID, usesBluetoothDevice } from '@app/cnst/hardware.cnst'
import { ICON } from '@app/cnst/icons.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { loader, LoaderType } from '@app/decorators/decorators'
import { swipeThreshold } from '@app/dir/swipe/swipe.directive'
import { SensitiveTrackersDismissReason } from '@app/modals/sensitive-trackers/sensitive-trackers.modal'
import { AddDataDay, AddDataService, Temperature } from '@app/pages/add-data/add-data.service'
import { AddDataHWDeviceService } from '@app/pages/add-data/add-data-hwDevice.service'
import { BasePage } from '@app/pages/base.page'
import { GraphSource } from '@app/pages/graph/graph.cnst'
import { GraphService } from '@app/pages/graph/graph.service'
import { nextIdle, runOnIdle } from '@app/perf/idle.util'
import { EntryMap } from '@app/reducers/addData.reducer'
import { AppSettingsFM } from '@app/srv/appSettings.cnst'
import { di, runInsideAngular } from '@app/srv/di.service'
import { EventService } from '@app/srv/event.service'
import {
  addNavParams,
  getNavigationState,
  getNavParams,
  removeNavParam,
} from '@app/srv/nav.service'
import { NotificationService } from '@app/srv/notification.service'
import { PreloadService } from '@app/srv/preload.service'
import { SnackbarService } from '@app/srv/snackbar.service'
import { dispatch, getState, select, select2 } from '@app/srv/store.service'
import { TourId, TourTooltip } from '@app/srv/tour.cnst'
import { TourService } from '@app/srv/tour.service'
import { UIService } from '@app/srv/ui.service'
import { prf } from '@app/util/perf.util'
import { setTimeoutNoZone } from '@app/util/zone.util'
import {
  GestureController,
  IonContent,
  IonRouterOutlet,
  NavController,
  Platform,
  ScrollCustomEvent,
} from '@ionic/angular'
import {
  _numberEnumValues,
  _randomInt,
  _uniqBy,
  IsoDateString,
  localDate,
  localTime,
  pDefer,
  UnixTimestampNumber,
} from '@naturalcycles/js-lib'
import {
  BatteryStatus,
  DailyEntryBM,
  dailyEntrySharedUtil,
  DataFlag,
  DEVIATION_REASON_FLAGS,
  Goal,
  HardwareId,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import {
  MultipleEntriesModalInput,
  MultipleEntriesService,
} from '@src/app/modals/multiple-entries/multiple-entries.service'
import {
  BluetoothService,
  BluetoothStatus,
  SyncedTemperature,
} from '@src/app/srv/bluetooth.service'
import { Orientation } from '@src/app/srv/orientation.model'
import { OrientationService } from '@src/app/srv/orientation.service'
import { PopupController } from '@src/app/srv/popup.controller'
import { NCHaptics } from '@src/typings/capacitor'
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs'
import { combineLatestWith, filter, map, tap } from 'rxjs/operators'
import { PregnancyEndTrigger } from '../flow/pregnancy-end/pregnancy-end.cnst'
import { PregnancyEndService } from '../flow/pregnancy-end/pregnancy-end.service'
import { UnplannedPregnancyTrigger } from '../flow/unplanned-pregnancy/unplanned-pregnancy.cnst'
import { UnplannedPregnancyService } from '../flow/unplanned-pregnancy/unplanned-pregnancy.service'
import { AddDataPopupService } from './add-data-popup.service'

type SwipeState = 'sliding' | 'center' | 'prev' | 'next'

@Component({
  selector: 'page-add-data',
  templateUrl: './add-data.page.html',
  styleUrls: ['./add-data.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('numpadAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('250ms ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('250ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
    trigger('slideAnimation', [
      state('sliding', style({})),
      state('center', style({})),
      state('prev', style({ transform: 'translateX(100%)' })),
      state('next', style({ transform: 'translateX(-100%)' })),
      transition('sliding => center', [
        animate('300ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition('center => next, sliding => next', animate('300ms ease-in-out')),
      transition('center => prev, sliding => prev', animate('300ms ease-in-out')),
    ]),
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('150ms 0ms ease-in', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class AddDataPage extends BasePage implements OnInit, AfterViewInit, OnDestroy {
  className = 'AddDataPage'
  public tourService = inject(TourService)
  private addDataService = inject(AddDataService)
  private activatedRoute = inject(ActivatedRoute)
  private cdr = inject(ChangeDetectorRef)
  private ionRouterOutlet = inject(IonRouterOutlet)
  private gestureController = inject(GestureController)
  private navController = inject(NavController)
  private popupController = inject(PopupController)
  private addDataHWDeviceService = inject(AddDataHWDeviceService)
  private bluetoothService = inject(BluetoothService)
  private eventService = inject(EventService)
  private notificationService = inject(NotificationService)
  private snackbarService = inject(SnackbarService)
  private uiService = inject(UIService)
  private orientationService = inject(OrientationService)
  private addDataPopupService = inject(AddDataPopupService)
  private multipleEntriesService = inject(MultipleEntriesService)
  private pregnancyEndService = inject(PregnancyEndService)
  private unplannedPregnancyService = inject(UnplannedPregnancyService)
  @ViewChild(IonContent)
  private content!: IonContent

  @ViewChild('slides')
  private slides!: ElementRef

  @ViewChild(AdditionalInfoComponent)
  private additionalInfo!: AdditionalInfoComponent

  private account$ = select2(s => s.account)
  public hwId$ = this.account$.pipe(map(acc => acc.hwId))
  private accountData$ = select2(s => s.accountData)

  @select(['addData', 'entryStash'])
  private entryStash$!: Observable<DailyEntryBM | undefined>

  @select(['appSettings'])
  private appSettings$!: Observable<AppSettingsFM | null>

  @select(['ui', 'blockAutoOpen'])
  private blockAutoOpen$!: Observable<boolean | undefined>

  @select(['oura', 'lastSyncClick'])
  private lastSyncClick$!: Observable<string | undefined>

  @select(['userFertility', 'entryMap'])
  private entryMap$!: Observable<EntryMap>

  protected override blockPopups = true

  @HostBinding('attr.date')
  private date: IsoDateString =
    this.activatedRoute.snapshot.paramMap.get(NavigationParams.DATE) || localDate.todayString()

  private blockNumpad = getNavParams()[NavigationParams.BLOCK_NUMPAD]
  public entry: DailyEntryBM = { date: this.date, dataFlags: [] }
  private entryUpdates$ = new BehaviorSubject<DailyEntryBM>(this.entry)
  public isToday = this.date === localDate.todayString()
  private goal?: Goal

  public config$ = new BehaviorSubject<AddDataConfig>({})
  public temperatureState$ = new BehaviorSubject<TemperatureState | undefined>(undefined)

  private yesterday = dayjs(this.date).subtract(1, 'day').toISODate()
  private tomorrow = dayjs(this.date).add(1, 'day').toISODate()
  public yesterdayTemperature?: Temperature
  public tomorrowTemperature?: Temperature

  public selectedDigits: number[] = []
  public numpadActive$ = new BehaviorSubject<boolean>(false)
  public formattedDate$ = new BehaviorSubject<string>(
    getNavParams()[NavigationParams.ADD_DATA_DATE] || '',
  )
  public isDeviating = false
  public hasSensitiveData = false
  public scrollDownButtonRotated = false
  public showScrollDownButton = true
  private scrollDownButtonPosition = 0
  public slidesState$ = new BehaviorSubject<SwipeState>('center')
  public translateX$ = new BehaviorSubject(0)
  public isAdditionalDataLoaded$ = new BehaviorSubject(false)

  public AVG_SEGMENT_WIDTH = 84
  private backButtonSub!: Subscription
  private swipeSource = AddDataSource.SWIPE
  private dayParams?: AddDataDay

  public TourTooltip = TourTooltip
  public TemperatureDataState = TemperatureDataState
  public ICON = ICON
  public HardwareId = HardwareId
  public ghostVisible$ = new BehaviorSubject<boolean>(
    getNavParams()[NavigationParams.ADD_DATA_GHOST] !== false,
  )

  public activeTour$ = this.tourService.activeTour$.pipe(tap(tour => (this.tourIsActive = !!tour)))
  public tourIsActive = false

  public showInAppTourDoneTooltip$ = new BehaviorSubject<boolean>(false)
  public showPregEndTooltip$ = new BehaviorSubject<boolean>(false)

  public lockScroll$ = new BehaviorSubject<boolean>(true)

  public ready = false
  private homeLoadingStarted = false

  public _hasDataToClear = false
  private set hasDataToClear(value: boolean) {
    if (this.addDataHWDeviceService.inWearableMode()) return

    this._hasDataToClear = value
  }

  public _showSkipButton = false
  private set showSkipButton(value: boolean) {
    if (this.addDataHWDeviceService.inWearableMode()) return

    this._showSkipButton = value
  }

  private hasSyncedBluetoothTemps = false
  private connectionTimeout?: NodeJS.Timeout
  private syncAlertTimeout?: NodeJS.Timeout

  private entryInited = pDefer()

  private syncedBluetoothTemperatures: SyncedTemperature[] =
    getNavigationState()[NavigationParams.BLUETOOTH_TEMPERATURES] || []

  constructor() {
    const elementRef = inject(ElementRef)

    super()

    this.elementRef = elementRef
  }

  protected override elementRef: ElementRef

  public ngOnInit(): void {
    this.setupSubscriptions()
  }

  public ngAfterViewInit(): void {
    const { source } = getAnalyticsProps()

    if (source === AddDataSource.TOUR_SKIPPED) {
      this.showTourDoneTooltip()
    }

    if (this.morningRouterOutletActivated()) {
      void import('../home/month/month.page')
    }

    const todayOffset = `${dayjs(this.date).diff(dayjs(), 'day')}`
    addAnalyticsProps({ todayOffset, date: this.date })

    if (!usesBluetoothDevice()) return

    const bluetoothStatus = this.bluetoothService.bluetoothStatus$.value

    // already got new temperatures
    if (this.syncedBluetoothTemperatures.length) {
      void this.handleSyncedBluetoothTemperatures(this.syncedBluetoothTemperatures)
      return

      // if opening add data while being connected to the thermometer, but without any new temperatures
    }
    if (bluetoothStatus === BluetoothStatus.CONNECTED) {
      void this.addDataPopupService.showNoNewDataAlert()
      return
    }

    if (this.isToday && !this.entry.temperature && bluetoothStatus === BluetoothStatus.SCANNING) {
      this.startConnectionTimeout()
    }
  }

  private updateDayParams(date: string): void {
    this.dayParams = this.addDataService.getDayParams(date)

    this.formattedDate$.next(this.dayParams.formattedDate || date)
  }

  private initEntry(): void {
    const entry = this.addDataService.getEntry(this.date)

    if (entry) this.applyEntryUpdates(entry)

    this.entryInited.resolve()
  }

  public override ionViewWillEnter(): void {
    void this.setReady()

    // update yesterday & tomorrow temperatures
    this.yesterdayTemperature = this.addDataService.getTemperature(this.yesterday)
    this.tomorrowTemperature = this.addDataService.getTemperature(this.tomorrow)

    // update dayparams to get correct date in header
    this.updateDayParams(this.date)

    if (this.slidesState$.value !== 'center') this.slidesState$.next('center')
    setTimeout(() => void this.content.scrollToTop(0))

    this.initEntry()
  }

  private async setReady(): Promise<void> {
    await firstValueFrom(
      this.orientationService.orientation$.pipe(
        filter(
          orientation =>
            orientation === Orientation.portrait || orientation === Orientation.portraitPrimary,
        ),
      ),
    )

    this.ready = true
  }

  public override async ionViewDidEnter(): Promise<void> {
    if (window.addDataOpenStarted) {
      prf('AddData.Open', window.addDataOpenStarted, undefined, false)
    } else if (window.addDataSwipeStarted) {
      prf('AddData.Swipe.Open', window.addDataSwipeStarted, undefined, false)
    } else {
      prf('AddData.Automatic.Open.Total')
    }

    const { account } = getState()

    const activeTour = this.tourService.activeTour$.getValue()
    const numpadActive =
      this.isToday &&
      !this.entry.updated &&
      !this.blockNumpad &&
      !activeTour &&
      account.hwId === HardwareId.ORAL_THERMOMETER
    this.numpadActive$.next(numpadActive)
    removeNavParam(NavigationParams.BLOCK_NUMPAD)

    setTimeout(() => {
      if (!this.numpadActive$.getValue()) {
        this.hideGhost()
      }
    }, 0)

    // disable swipe to go back
    this.uiService.swipeGesture$.next(false)
    removeNavParam(NavigationParams.ADD_DATA_GHOST)

    this.backButtonSub = di.get(Platform).backButton.subscribeWithPriority(1, () => {
      // Otherwise the tour steps and screen dont match and no tooltips appear
      if (this.tourService.isTourActive()) return
      void this.close()
    })

    if (getNavParams()[NavigationParams.SHOW_PREG_END_TOOLTIP]) {
      this.showPregEndTooltip$.next(true)

      removeNavParam(NavigationParams.SHOW_PREG_END_TOOLTIP)
    }

    if (this.isToday) {
      setTimeoutNoZone(() => dispatch('setLastAutoOpen', this.date))
    }

    if (getState().account.hwId === HardwareId.APPLE_WATCH) {
      this.showAWNoTempsModal()
    }
  }

  private showAWNoTempsModal(): void {
    if (this.isToday && this.entry.temperature) return

    const { lastAppleWatchSyncDate, noAppleWatchTempsModalShownDate, hasCompatibleAppleWatch } =
      getState().userSettings

    if (
      // hasCompatibleAppleWatch is always set to boolean/null during the AW Connection flow
      hasCompatibleAppleWatch === undefined ||
      lastAppleWatchSyncDate
    ) {
      return
    }

    if (
      !noAppleWatchTempsModalShownDate ||
      (noAppleWatchTempsModalShownDate &&
        dayjs().subtract(7, 'd').isSameOrAfter(noAppleWatchTempsModalShownDate))
    ) {
      void this.addDataPopupService.showAWNoTempsModal()
      dispatch('extendUserSettings', {
        noAppleWatchTempsModalShownDate: localDate.todayString(),
      })
    }
  }

  private applyEntryUpdates(entry: DailyEntryBM): void {
    this.modifyDailyEntry(entry, true)

    if (entry.updated) {
      this.hasDataToClear = true
    }
  }

  private initSlides(): void {
    const gesture = this.gestureController.create(
      {
        el: this.slides.nativeElement,
        threshold: 15,
        gestureName: 'nc-swipe',
        direction: 'x',
        passive: false,
        onStart: () => this.onStart(),
        onMove: (ev: any) => this.onMove(ev),
        onEnd: (ev: any) => this.onEnd(ev),
      },
      false,
    )

    gesture.enable()
  }

  private onStart(): void {
    this.slidesState$.next('sliding')
  }

  private onMove(event: any): void {
    runInsideAngular(() => this.translateX$.next(event.deltaX))
  }

  private onEnd(event: any): void {
    const { deltaX } = event
    let state: SwipeState

    if (deltaX > swipeThreshold) state = 'prev'
    else if (deltaX < -swipeThreshold && !this.isToday) state = 'next'
    else state = 'center'

    runInsideAngular(() => this.slidesState$.next(state))
  }

  public slideDidChange(event: AnimationEvent): void {
    // Ignore callbacks triggered on page init and on destroy
    if (event?.fromState === 'void' || event.toState === 'void') return

    const { value: slidesState } = this.slidesState$

    if (slidesState === 'center') {
      this.translateX$.next(0)
      return
    }
    if (slidesState !== 'next' && slidesState !== 'prev') return

    const isSnackbarShown = this.addDataPopupService.showIncompleteTempSnackbar(this.selectedDigits)
    if (isSnackbarShown) {
      this.updateDayParams(this.date)
      this.slidesState$.next('center')
      return
    }

    this.addDataService.addToModifiedDailyEntries(this.entry)

    const date = slidesState === 'next' ? this.tomorrow : this.yesterday

    window.addDataOpenStarted = undefined

    const sourcePath = getNavParams()[NavigationParams.SOURCE_PATH]

    addNavParams({
      [NavigationParams.ADD_DATA_GHOST]: false,
      [NavigationParams.ADD_DATA_DATE]: this.formattedDate$.value,
      [NavigationParams.BLOCK_NUMPAD]: true,
      [NavigationParams.SOURCE_PATH]: sourcePath || ROUTES.TodayPage,
    })
    addAnalyticsProps({ source: this.swipeSource })

    const morningRouterOutletActivated = this.morningRouterOutletActivated()

    window.addDataSwipeStarted = Date.now()
    void this.navigateForward(`${ROUTES.AddData}/${date}`, undefined, {
      animated: !morningRouterOutletActivated,
      animation: fadeAnimation,
    }).then(() => {
      if (morningRouterOutletActivated) {
        setTimeout(() => this.closeAddDataOutlet(true))
      }
    })
  }

  private slide(state: SwipeState): void {
    this.swipeSource = AddDataSource.SWIPE_BUTTON
    this.slidesState$.next(state)

    const date = state === 'next' ? this.tomorrow : this.yesterday

    this.updateDayParams(date)
  }

  public slidePrev(): void {
    this.slide('prev')
  }

  public slideNext(): void {
    this.slide('next')
  }

  private hideGhost(): void {
    this.ghostVisible$.next(false)
    this.initSlides()
  }

  public override ionViewWillLeave(): void {
    // enable swipe to go back again
    this.uiService.swipeGesture$.next(true)
  }

  public override ionViewDidLeave(): void {
    this.backButtonSub.unsubscribe()
  }

  public override async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy()

    this.showAlertIfMeasurementsOnHold()

    window.addDataOpenStarted = undefined
    window.addDataNumpadCloseStarted = undefined

    this.clearConnectionTimeouts()

    // Show popup for OURA users that their permissions
    // are off after 7 days they switch or register
    runOnIdle(async _ => {
      const { account, hwChanges, userSettings } = getState()
      await this.addDataService.showOuraPermissionsModalIfNeeded(hwChanges, account, userSettings)
    })
  }

  public async close(): Promise<void> {
    const savedEntry = this.addDataService.getEntry(this.date, true)

    if (this.addDataService.checkIfEntryChanged(savedEntry, this.entry)) {
      const { shouldExit } = await this.addDataPopupService.showExitAlert()
      if (shouldExit) {
        await this.popupController.dismissActive()
        await this.goBack(true, true)
        dispatch('clearEntryStash')
      }

      return
    }

    void this.goBack()
  }

  private setupSubscriptions(): void {
    this.subscriptions.push(
      this.entryStash$.subscribe(() => {
        this.entry = this.addDataService.getEntry(this.date)
        this.entryUpdates$.next(this.entry)
        this.cdr.markForCheck()
      }),

      this.account$
        .pipe(
          combineLatestWith(
            this.entryUpdates$,
            this.appSettings$,
            this.lastSyncClick$,
            this.accountData$,
          ),
        )
        .subscribe(([account, entry, settings, _lastSyncClick, accountData]) => {
          const config = this.addDataService.getAddDataConfig(
            account,
            accountData,
            entry,
            settings,
            HW_CONFIG_BY_HWID[account.hwId].type,
          )
          this.config$.next(config)

          this.goal = account.goal

          this.showScrollDownButton = !!(
            config.libidoSegments ||
            config.skinFlags ||
            config.sleepSegments ||
            config.painFlags ||
            config.cmQuantitySegments ||
            config.moodFlags
          )

          this.showOuraSnackbar()

          const temperatureState = this.addDataService.getTemperatureState(entry)

          this.temperatureState$.next(temperatureState)

          this.cdr.markForCheck()
        }),

      this.entryMap$.subscribe(entryMap => {
        const entryFromState = entryMap[this.date]

        // merge dataflag arrays and prevent duplicates
        const dataFlags = new Set([...(entryFromState?.dataFlags || []), ...this.entry.dataFlags])

        const entry: DailyEntryBM = {
          ...entryFromState,
          ...this.entry,
          dataFlags: Array.from(dataFlags),
        }

        this.modifyDailyEntry(entry, true)
      }),

      this.tourService.tourCompleted$.subscribe(tour => {
        if (tour === TourId.APP) {
          this.showTourDoneTooltip()
        }
      }),

      this.blockAutoOpen$.subscribe(block => {
        // load calendar page if blocked by external link
        if (block && this.morningRouterOutletActivated()) {
          this.loadTodayPage()
          this.closeAddDataOutlet()
        }
      }),

      this.bluetoothService.temperatures$.subscribe(temperatures => {
        if (temperatures.length) {
          void this.handleSyncedBluetoothTemperatures(temperatures)
          return
        }

        void this.addDataPopupService.showNoNewDataAlert()
      }),

      this.bluetoothService.connected$.subscribe(connected => {
        if (connected) this.clearConnectionTimeouts()
      }),

      this.bluetoothService.bluetoothEnabled$.subscribe(() => {
        const temperatureState = this.addDataService.getTemperatureState(this.entry)
        this.temperatureState$.next(temperatureState)
      }),

      this.eventService.internalLinkClick$.subscribe(async link => {
        const morningRouterOutletActivated = this.morningRouterOutletActivated()

        await this.navigateForward(
          link,
          { navParams: { [NavigationParams.SOURCE_PATH]: ROUTES.AddData } },
          morningRouterOutletActivated ? { replaceUrl: true, animated: false } : undefined,
        )

        if (!morningRouterOutletActivated) return
        this.closeAddDataOutlet()
      }),
    )
  }

  private modifyDailyEntry(_entry: DailyEntryBM, replace = false): void {
    const { entryStash, modifiedDailyEntries } = getState().addData

    if (!replace) {
      _entry = {
        updated: localTime.nowUnix(),
        ...entryStash,
        ..._entry,
        skipped: undefined,
      }
    }

    this.hasSensitiveData = this.addDataService.getSensitiveData(_entry).length > 0
    this.isDeviating =
      (!!_entry.temperature &&
        !!_entry.dataFlags.filter(flag => DEVIATION_REASON_FLAGS.has(flag)).length) || // todo uf
      this.temperatureState$.value?.state === TemperatureDataState.EXCLUDED_FIRMWARE
    this.showSkipButton = this.isToday && dailyEntrySharedUtil.isEmptyOrSkipped(_entry)

    this.selectedDigits = this.addDataService.getDigitsFromTemperature(_entry.temperature)

    dispatch('setEntryStash', _entry)

    if (modifiedDailyEntries[this.date]) {
      const entry: DailyEntryBM = { date: this.date, dataFlags: [] }
      const entryMap = { [this.date]: entry }

      dispatch('extendModifiedDailyEntries', entryMap)
    }
  }

  private clearConnectionTimeouts(): void {
    if (this.connectionTimeout) clearTimeout(this.connectionTimeout)
    if (this.syncAlertTimeout) clearTimeout(this.syncAlertTimeout)
  }

  private showTourDoneTooltip(): void {
    void this.content.scrollToPoint(0, 0, 400)

    setTimeout(() => this.showInAppTourDoneTooltip$.next(true), 2000)
  }

  public onTemperatureChange(
    source: string,
    temperature?: number,
    timestamp?: UnixTimestampNumber,
  ): void {
    if (temperature === this.entry.temperature && !timestamp) return // nothing changed

    const entry: DailyEntryBM = {
      date: timestamp ? dayjs.unix(timestamp).toISODate() : this.date,
      temperature,
      temperatureMeasuredTimestamp: timestamp,
      dataFlags: this.entry.dataFlags.filter(flag => flag !== DataFlag.DEVIATION_REASON_ALGO),
    }

    this.modifyDailyEntry(entry)

    if (temperature) {
      // dismiss the numpad after a little timeout so the ripple have time to ripple
      setTimeout(() => {
        this.onNumpadDismiss()
        void di
          .get(AnalyticsService)
          .trackEvent(EVENT.TEMPERATURE_ENTERED, { temperature, source, date: this.date })
      }, 200)
    }
  }

  public onTemperatureDisplayClick(): void {
    if (this.addDataHWDeviceService.inWearableMode()) return

    this.numpadActive$.next(true)

    const { temperature } = this.entry
    void di.get(AnalyticsService).trackEvent(EVENT.TEMPERATURE_DISPLAY_CLICK, { temperature })
  }

  public onTemperatureStateClick(stateClick: TemperatureStateClick): void {
    const { hwId, state } = stateClick

    const allowedStates = _numberEnumValues(TemperatureDataState).filter(key => {
      if (key === TemperatureDataState.NOT_SYNCED_TIMEOUT) return false
      if (hwId !== HardwareId.OURA && key === TemperatureDataState.NOT_SYNCED) return false
      return true
    })

    if (allowedStates.includes(state)) {
      // Copy for WAITING_FOR_DATA & NOT_SYNCED states is identical
      const state_ =
        state === TemperatureDataState.WAITING_FOR_DATA ? TemperatureDataState.NOT_SYNCED : state
      const title = `info-tempDataState--${TemperatureDataState[state_]}--title`
      const body = `info-tempDataState--${TemperatureDataState[state_]}--body`
      const id = TemperatureDataState[state].toLowerCase()

      void this.addDataPopupService.openInfoModal(title, body, id)
    }

    if (
      state !== TemperatureDataState.NOT_SYNCED &&
      state !== TemperatureDataState.NOT_SYNCED_TIMEOUT
    ) {
      return
    }

    const temperatureState = this.temperatureState$.value
    this.temperatureState$.next({
      ...temperatureState,
      state: TemperatureDataState.NOT_SYNCED,
    })

    if (hwId !== HardwareId.OURA) {
      this.syncAlertTimeout = setTimeout(() => this.showConnectionAlert(), 10_000)
    }
  }

  public additionalDataChange(entry: DailyEntryBM): void {
    this.modifyDailyEntry(entry)
  }

  public onNoLongerPregnant(trigger: PregnancyEndTrigger): void {
    void this.openPregnancyEndPage(trigger)
  }

  public scrollButtonClicked(): void {
    if (this.scrollDownButtonRotated) {
      void this.content.scrollToTop(200)
    } else {
      const { offsetTop } = this.additionalInfo.scrollButtonContainer.nativeElement
      void this.content.scrollToPoint(0, offsetTop, 200)
    }
  }

  public onAdditionalDataLoaded(): void {
    this.isAdditionalDataLoaded$.next(true)

    if (this.morningRouterOutletActivated()) {
      this.loadTodayPage()
    }
  }

  public onScrollLock(lock: boolean): void {
    this.lockScroll$.next(!lock)
    this.cdr.detectChanges()
  }

  @loader(LoaderType.BUTTON)
  public async onSubmitButtonClick(skip = false): Promise<void> {
    const canAddData = this.addDataService.canAddData()
    if (!canAddData) {
      void this.showBuySubscriptionAlert()
      return
    }

    const incompleteTempSnackbarShown = this.addDataPopupService.showIncompleteTempSnackbar(
      this.selectedDigits,
    )

    if (incompleteTempSnackbarShown) return

    void this.addDataService.submitButtonPressed(this.entry, skip).then(async () => {
      if (skip) return

      const crmPopupShown = await this.notificationService.checkCRM()
      if (crmPopupShown) return

      const scienceConsentShown = await this.addDataService.showScienceConsentModal()
      if (scienceConsentShown) return
    })

    await this.goBack(false)
  }

  private async showBuySubscriptionAlert(): Promise<void> {
    const { renewAccountPressed } =
      (await this.addDataPopupService.showBuySubscriptionAlert()) || {}
    if (!renewAccountPressed) return
    await this.navController.pop()
    setTimeout(() => {
      void this.navController.navigateForward(ROUTES.ManageAccountPage)
    }, 400)
    void di.get(AnalyticsService).trackEvent(EVENT.RENEW_SUBSCRIPTION_CLICK)
  }

  public async onClearButtonClick(): Promise<void> {
    const { hasDataToClear } = await this.addDataPopupService.presentAddDataClearPopup()
    this.hasDataToClear = hasDataToClear
    if (!hasDataToClear) {
      this.clearEntry()
    }
  }

  private clearEntry(): void {
    const entry: DailyEntryBM = { date: this.date, dataFlags: [] }

    this.modifyDailyEntry(entry, true)

    const entryMap = { [this.date]: entry }
    dispatch('extendModifiedDailyEntries', entryMap)
  }

  private async goBack(saveData = true, ignoreStash = false): Promise<any> {
    if (saveData) {
      void this.addDataService.saveModifiedDailyEntries(ignoreStash)
    }

    if (this.morningRouterOutletActivated()) {
      this.loadTodayPage()
      return this.closeAddDataOutlet()
    }

    const sourcePath = getNavParams()[NavigationParams.SOURCE_PATH]
    removeNavParam(NavigationParams.SOURCE_PATH)

    if (sourcePath?.includes(ROUTES.GraphPage) && window.Capacitor.isNativePlatform()) {
      return await di.get(GraphService).openGraph(GraphSource.ADD_DATA_CLOSE, sourcePath)
    }

    if (
      this.elementRef.nativeElement.classList.contains('can-go-back') &&
      sourcePath &&
      !sourcePath.includes(ROUTES.AddData)
    ) {
      return await this.navController.navigateBack(sourcePath, {
        animation: verticalSlideAnimation,
      })
    }

    return await this.navController.navigateBack(ROUTES.HomePage, {
      animation: verticalSlideAnimation,
    })
  }

  private closeAddDataOutlet(fade = false): void {
    this.ionRouterOutlet.nativeEl.addEventListener('transitionend', this.transitionEndListener)
    this.ionRouterOutlet.nativeEl.classList.add(fade ? 'fade' : 'slide')
  }

  private transitionEndListener = (): void => {
    if (window.addDataCloseNoDataStarted) {
      prf('AddData.Close.noData', window.addDataCloseNoDataStarted, undefined, false)
    }

    if (window.addDataCloseSaveDataStarted) {
      prf('AddData.Close.saveData', window.addDataCloseSaveDataStarted, undefined, false)
    }

    window.addDataCloseNoDataStarted = undefined
    window.addDataCloseSaveDataStarted = undefined

    this.ionRouterOutlet.nativeEl.removeEventListener('transitionend', this.transitionEndListener)
    this.ionRouterOutlet.ngOnDestroy()

    // Update status bar colour
    this.eventService.transitionDone$.next()
    this.updateNavigationBarColor()
  }

  public async openSensitiveTrackers(): Promise<void> {
    const { entry, dayParams } = this

    const { data: modalData } = await this.addDataPopupService.presentSensitiveTrackersModal(
      entry,
      dayParams,
    )

    this.handleSensitiveDataDismissReason(modalData)

    const data = getNavParams()[NavigationParams.TRACKERS]
    removeNavParam(NavigationParams.TRACKERS)

    if (!data) return

    let newDataFlags = [...this.entry.dataFlags, data.emergency]
    newDataFlags = newDataFlags.filter(
      (flag, index) => !!flag && newDataFlags.indexOf(flag) === index,
    )

    const iudIndex = newDataFlags.indexOf(DataFlag.MORE_EMERGENCY_IUD)
    if (iudIndex !== -1 && data.emergency !== DataFlag.MORE_EMERGENCY_IUD) {
      newDataFlags.splice(iudIndex, 1)
    }

    const pillIndex = newDataFlags.indexOf(DataFlag.MORE_EMERGENCY_PILL)
    if (pillIndex !== -1 && data.emergency !== DataFlag.MORE_EMERGENCY_PILL) {
      newDataFlags.splice(pillIndex, 1)
    }

    const updatedEntry = {
      ...this.entry,
      dataFlags: newDataFlags,
      pregTest: data.pregTest,
    }

    this.modifyDailyEntry(updatedEntry)
  }

  public openTrackerSettings(): void {
    void this.navigateForward(
      ROUTES.SettingsTrackersPage,
      {
        navParams: {
          [NavigationParams.SOURCE_PATH]: ROUTES.AddData + '/' + this.date,
        },
        analytics: { source: this.className },
      },
      {
        animation: verticalSlideAnimation,
      },
    )

    if (!this.morningRouterOutletActivated()) return
    this.closeAddDataOutlet()
  }

  private handleSensitiveDataDismissReason(data?: SensitiveTrackersDismissReason): void {
    switch (data) {
      case SensitiveTrackersDismissReason.SAVED_END_PREG:
        void this.goBack()
        break

      case SensitiveTrackersDismissReason.OPEN_END_PREG_NEGATIVE_TEST:
        this.openPregnancyEndPage(PregnancyEndTrigger.NEGATIVE_TEST)
        break

      case SensitiveTrackersDismissReason.OPEN_END_PREG_NO_LONGER_PREGNANT:
        this.openPregnancyEndPage(PregnancyEndTrigger.NO_LONGER_PREGNANT)
        void di
          .get(AnalyticsService)
          .trackEvent(EVENT.NO_LONGER_PREGNANT, { pregnancyEndedDate: this.date })
        break

      case SensitiveTrackersDismissReason.OPEN_UNPLANNED_PREG_POSITIVE_TEST:
        this.openPregnancyReasonPage()
        break
    }
  }

  private openPregnancyEndPage(trigger: PregnancyEndTrigger): void {
    if (this.morningRouterOutletActivated()) {
      this.closeAddDataOutlet()
    }

    const goal = this.goal === Goal.PREVENT ? Goal.PREVENT : undefined
    const pregnancyEndDate = trigger === PregnancyEndTrigger.NEGATIVE_TEST ? this.date : undefined

    void this.pregnancyEndService.startPregnancyEndFlow({
      trigger,
      goal,
      pregnancyEndDate,
    })
  }

  private openPregnancyReasonPage(): void {
    if (this.morningRouterOutletActivated()) {
      this.closeAddDataOutlet()
    }

    void this.unplannedPregnancyService.startUnplannedPregnancyFlow({
      trigger: UnplannedPregnancyTrigger.PREG_TEST,
      pregnancyStartDate: this.date,
      currentGoal: this.goal || Goal.PREVENT,
    })
  }

  public onNumpadDismiss(): void {
    window.addDataNumpadCloseStarted = Date.now()

    void nextIdle(500, 1000).then(() => this.hideGhost())
    this.numpadActive$.next(false)

    this.addDataPopupService.showIncompleteTempSnackbar(this.selectedDigits)
  }

  public onNumpadAnimationDone(): void {
    this.updateNavigationBarColor()
    if (this.numpadActive$.value) return

    prf('AddData.NumpadClose', window.addDataNumpadCloseStarted, undefined, false)
  }

  public onScroll(event: ScrollCustomEvent): void {
    const { scrollTop } = event.detail

    this.scrollDownButtonRotated = scrollTop > this.scrollDownButtonPosition / 2
  }

  private showAlertIfMeasurementsOnHold(): void {
    const { addData, ui } = getState()
    // has measurements on hold
    if (Object.keys(addData.modifiedDailyEntries).length > 0 && !ui.online) {
      void this.addDataPopupService.showOfflineAlert()
      dispatch('setGhostLoader', false)
    }
  }

  private async showConnectionAlert(): Promise<void> {
    const alert = await this.addDataPopupService.presentUnableToConnectAlert(
      () => this.onTemperatureDisplayClick(),
      () => this.startConnectionTimeout(),
    )

    alert.addEventListener('click', async (event: any) => {
      if (event.target.tagName !== 'A') return

      event.preventDefault()
      event.stopPropagation()

      const morningRouterOutletActivated = this.morningRouterOutletActivated()

      await this.popupController.dismissActive()
      await this.navigateForward(
        ROUTES.TroubleshootingPage,
        { navParams: { [NavigationParams.SOURCE_PATH]: ROUTES.AddData } },
        morningRouterOutletActivated ? { replaceUrl: true, animated: false } : undefined,
      )

      if (!morningRouterOutletActivated) return
      this.closeAddDataOutlet()
    })
  }

  private showOuraSnackbar(): void {
    if (!this.addDataHWDeviceService.inOuraMode()) return

    const temperatureDataState = this.temperatureState$.value?.state
    if (!temperatureDataState) return

    const snackbar = OURA_SNACKBAR[temperatureDataState]
    if (!snackbar) return

    this.snackbarService.showSnackbar(snackbar)
  }

  private showTempSyncedSnackbar(messageId?: number): void {
    messageId ||= this.getSyncedMessageId()

    const snackbar = {
      ...SNACKBAR.UEBE_TEMPERATURE_SYNCED,
      message: `uebe-temperature-synced-msg--${messageId}`,
    }

    this.snackbarService.showSnackbar(snackbar)
  }

  private getSyncedMessageId(): number {
    const batteryStatus = getState().hwDevice?.batteryStatus || BatteryStatus.HIGH

    if (batteryStatus === BatteryStatus.CRITICALLY_LOW) return 6
    if (batteryStatus === BatteryStatus.LOW) return 5

    return _randomInt(1, 4)
  }

  private morningRouterOutletActivated(): boolean {
    // HomePage is already loaded if 'morning' RouterOutlet isn't activated
    return (
      !this.ionRouterOutlet.isActivated || this.ionRouterOutlet.activatedRoute.outlet === 'morning'
    )
  }

  private startConnectionTimeout(): void {
    clearTimeout(this.connectionTimeout)
    this.connectionTimeout = setTimeout(() => {
      const temperatureState = this.temperatureState$.value
      if (temperatureState?.state !== TemperatureDataState.EXCLUDED_FIRMWARE) {
        this.temperatureState$.next({
          ...temperatureState,
          state: TemperatureDataState.NOT_SYNCED_TIMEOUT,
        })
      }
    }, 45_000)
  }

  private loadTodayPage(): void {
    if (this.homeLoadingStarted) return

    void this.navController.navigateRoot(ROUTES.TodayPage)

    this.homeLoadingStarted = true

    // preload bottom tab pages
    void nextIdle(1000).then(() => void di.get(PreloadService).preloadRoutes())
  }

  private async handleSyncedBluetoothTemperatures(
    temperatures: SyncedTemperature[],
  ): Promise<void> {
    await this.entryInited

    if (window.Capacitor.isNativePlatform()) void NCHaptics.impact()
    this.hasSyncedBluetoothTemps ||= !!temperatures.length

    // dismiss bluetooth connection alert if open
    const activePopup = this.popupController.getActivePopup()
    if (activePopup?.id === 'alert-addData-BLE-connection') {
      await this.popupController.dismissActive()
    }

    const firstDate = dayjs.unix(temperatures[0]!.timestamp).toISODate()

    // only one temperature for current day
    if (
      temperatures.length === 1 &&
      firstDate === this.entry.date &&
      !this.entry.temperatureMeasuredTimestamp
    ) {
      const { temperature, timestamp } = temperatures[0]!

      if (!this.entry.temperature || this.entry.temperature === temperature) {
        this.onTemperatureChange('bluetooth', temperature, timestamp)
        if (this.temperatureState$.value?.state === TemperatureDataState.EXCLUDED_FIRMWARE) {
          this.snackbarService.showSnackbar(SNACKBAR.CRITICAL_FIRMWARE_UPDATE)
          return
        }
        this.showTempSyncedSnackbar(temperatures[0]?.successMsgId)
        this.cdr.detectChanges()
        return
      }
    }

    const entries: DailyEntryBM[] = temperatures.map(t =>
      this.addDataService.syncedBluetoothTemperatureToDailyEntry(t),
    )

    const { entryMap } = getState().userFertility
    let hasSavedManualEntries = false

    // get existing entries with temperatures
    _uniqBy(entries, entry => entry.date).forEach(entry => {
      const savedEntry = entryMap[entry.date]
      if (!savedEntry?.temperature || savedEntry.temperature === entry.temperature) {
        return
      }

      if (!savedEntry.temperatureMeasuredTimestamp) {
        hasSavedManualEntries = true
      }

      entries.push({ ...savedEntry })
    })

    // temperature conflict alert
    if (hasSavedManualEntries) {
      await this.addDataPopupService.showTempConflictAlert()
    }

    const uniqueDates = new Set(entries.map(e => e.date))

    // multi bluetooth temps on same days
    if (uniqueDates.size !== entries.filter(entry => !!entry.temperatureMeasuredTimestamp).length) {
      await this.addDataPopupService.presentMultipleTempsAlert()
    }

    const componentProps: MultipleEntriesModalInput = {
      title: 'uebe-multi-temps-title',
      body: 'uebe-multi-temps-body',
      editable: true,
      dismissTitle: 'txt-save',
      entries,
    }

    const entriesToSave = await this.multipleEntriesService.showMultipleEntriesModal(componentProps)

    if (entriesToSave.length) {
      this.addDataService.addBluetoothEntriesToModifiedEntries(entriesToSave)
    }

    // Call modifyDailyEntry if there's an entry to be saved for the current date
    // modifyDailyEntry moves it from modifiedDailyEntries to entryStash, preventing a mismatch between the two places
    const currentDateEntry = entriesToSave.find(e => e.date === this.date)

    if (currentDateEntry) {
      this.modifyDailyEntry(currentDateEntry)
    }
  }
}
