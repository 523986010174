import { inject, Injectable } from '@angular/core'
import { LINK } from '@app/cnst/links.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { AdminService } from '@app/srv/admin.service'
import { getExtraHeaders, processBackendResponse } from '@app/srv/api.service'
import { di, runOutsideAngular } from '@app/srv/di.service'
import { InAppBrowserService } from '@app/srv/inappbrowser.service'
import { storageService } from '@app/srv/storage.service'
import { dispatch, getState, StoreService, USER_SETTINGS } from '@app/srv/store.service'
import { NavController } from '@ionic/angular'
import { topbar } from '@naturalcycles/frontend-lib'
import {
  _Memo,
  _stringify,
  getFetcher,
  IsoDateString,
  localDate,
  pHang,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  AccountChangeHardwareInput,
  AppSettings,
  AvgCycleLengthOption,
  BackendResponseFM,
  BackendResponseFMResp,
  backendResponseReviver,
  BirthControlMethodBreakpointOption,
  ConceiveTimingBreakpointOption,
  ConsideringPurchaseOption,
  DiscussedFertilityOption,
  Goal,
  GoalBreakpointOption,
  HardwareDeviceTM,
  HardwareId,
  HormonalBirthControlHowLongBreakpointOption,
  HormonalBirthControlUsageBreakpointOption,
  PartnerInvolvementOption,
  PeriodRegularityOption,
  ProductKey,
  QuizDataInput,
  SleepOption,
  SleepWithTrackerOption,
  StatementDescriptionOption,
  WearWatchToBedOption,
} from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { AuthPageMode } from '@src/typings/auth'
import { ROUTES } from '../cnst/nav.cnst'
import { NavigationParams } from '../cnst/nav.params.cnst'
import { urlUtil } from '../util/url.util'
import { appSettingsTMtoFM } from './appSettings.cnst'
import { storage3Service } from './storage3/storage3.service'

export enum OuraDataType {
  NORMAL = 'NORMAL',
  ADJUSTED = 'ADJUSTED',
  SHORT_SLEEP = 'SHORT_SLEEP',
  EXCLUDED = 'EXCLUDED',
}

let lagRadarDestroy: (() => void) | undefined

const qaApi = getFetcher({
  logRequest: true,
  logResponse: true,
  baseUrl: `${env.apiUrl}/qa`,
  credentials: 'include',
  timeoutSeconds: 120,
  jsonReviver: backendResponseReviver,
})
  .onBeforeRequest(async req => {
    const headers = await getExtraHeaders()
    Object.assign(req.init.headers, headers)

    topbar.show()
  })
  .onAfterResponse(async res => {
    if (!res.ok && res.fetchResponse?.status === 401) {
      alert('401 received, will redirect to login page..')
      di.get(AdminService).redirectToLogin()
      await pHang()
    }

    if (!res.ok) {
      alert(_stringify(res.err))
      return
    }

    if (res.body && res.req.responseType === 'json') {
      processBackendResponse(res.body)
    }

    topbar.hide()
  })

@Injectable({ providedIn: 'root' })
export class QAService {
  private storeService = inject(StoreService)

  @_Memo()
  async getPersonas(): Promise<any[]> {
    const r = await qaApi.get<any>(`personas`)
    return r?.personas
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async loginToPersona(personaCode: string): Promise<void> {
    this.storeService.persistenceEnabled = false

    const { backendResponse } = await qaApi.get<BackendResponseFMResp>(
      `loginToPersona/${personaCode}`,
    )
    const { sessionId } = backendResponse

    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
    })

    location.reload()
    await pHang()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async addDataToPersona(
    cycles: number,
    cycleDay: number,
    cycleLength = 30,
    measureRatio = 0.12,
    skipTrackers?: boolean,
  ): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(
      `addDataToPersona/${cycles}/${cycleDay}/${cycleLength}/${measureRatio}/${skipTrackers}`,
    )
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async makePersonaPregnant(cycles: number, pregDate?: string): Promise<void> {
    pregDate ||= localDate.todayString()

    await qaApi.put<BackendResponseFMResp>(`makePersonaPregnant/${cycles}/${pregDate}`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async addRealPregnantData(): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`addPregnancyDataToPersona`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async setCompleteDate(completeDate: IsoDateString): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`completeDate/${completeDate}`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async loginToAccount(idOrEmail: string): Promise<void> {
    const { sessionId, hwDevice, appSettings, hwChanges } = await qaApi.post<{
      sessionId: string
      hwDevice: HardwareDeviceTM | null
      appSettings: AppSettings | undefined
      hwChanges: StringMap<HardwareId> | undefined
    }>(`loginToAccount`, {
      json: {
        idOrEmail,
      },
    })

    this.storeService.persistenceEnabled = false
    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
      hwDevice,
      appSettings: appSettings && appSettingsTMtoFM(appSettings),
      hwChanges,
    })

    // Remove query strings to avoid re-triggering in case of having "adminLoginToPersonalId"
    const url = urlUtil.buildUrl(window.location.pathname)
    window.history.replaceState(undefined, '', url)

    location.reload()
    return await pHang()
  }

  async createMessage(msgKey: string): Promise<void> {
    await qaApi.post<BackendResponseFMResp>(`createMessage/${msgKey}/`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async toggleHardwareId(hwId: HardwareId): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`accounts/changeHardware`, {
      baseUrl: env.apiUrl, // hack
      json: {
        hwId,
      } as AccountChangeHardwareInput,
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async buyThermometer(key: ProductKey): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`buyThermometer`, {
      json: {
        key,
      },
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async getAllHardwares(): Promise<void> {
    await qaApi.get<void>(`hardwares`)
  }

  async connectToOuraMockedAccount(): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`connectMockedOuraAccount`)
  }

  goToOuraAuth(): void {
    const { id } = getState().account
    const url = `${env.prod ? LINK.OURA_AUTH : LINK.OURA_AUTH_INTERNAL}${id}`
    void di.get(InAppBrowserService).open(url)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async syncOuraData(dataType = OuraDataType.NORMAL): Promise<void> {
    const nDays = parseInt(prompt('number of days', '1') || '1')
    const todayDate = localDate.todayString()
    const endDate = prompt('End date', todayDate) || todayDate

    await qaApi.put<BackendResponseFMResp>(`syncOuraData`, {
      searchParams: {
        nDays,
        dataType,
        endDate,
      },
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async disconnectOuraAccount(): Promise<void> {
    await qaApi.post<BackendResponseFMResp>(`disconnectOuraAccount`)
  }

  async toggleLagRadar(): Promise<void> {
    if (lagRadarDestroy) {
      lagRadarDestroy()
      lagRadarDestroy = undefined
      storageService.remove('lagRadar')
    } else {
      const { lagRadar } = await import('@app/vendor/lag-radar')

      runOutsideAngular(() => {
        lagRadarDestroy = lagRadar({
          frames: 50, // number of frames to draw, more = worse performance
          speed: 0.0017, // how fast the sweep moves (rads per ms)
          size: 200, // outer frame px
          inset: 3, // circle inset px
          parent: document.getElementById('lagradar'), // DOM node to attach to
        })

        storageService.put('lagRadar', '1')
      })
    }
  }

  async addPartner(): Promise<BackendResponseFM> {
    return await qaApi.post('partners/add')
  }

  async loginToPartner(id: string): Promise<void> {
    this.storeService.persistenceEnabled = false

    const { backendResponse } = await qaApi.post<BackendResponseFMResp>(`partners/login/${id}`)
    const { sessionId, partnerAccount } = backendResponse

    this.storeService.dispatch('logout')
    await storage3Service.clear([USER_SETTINGS])

    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
      partnerAccount,
    })

    location.reload()
    await pHang()
  }

  async bypassQuiz(goal: Goal): Promise<void> {
    dispatch('clearQuizData')

    /**
     * These four constants would look a lot nicer when DRYed up,
     * but for some reason it doesn't work when I spread a shared object into the prevent or plan objects
     * Keeping this approach for now, as it also makes it easier to edit the separate flows and data in the future
     * */
    const dataPrevent: QuizDataInput = {
      name: 'Amalia',
      dateOfBirth: '1993-08-17',
      fertilityGoal: GoalBreakpointOption.prevent,
      birthControlMethod: BirthControlMethodBreakpointOption.pill,
      sideEffects: [],
      hormonalBirthControlHowLong: HormonalBirthControlHowLongBreakpointOption.moreThanAYear,
      medicalConditions: [],
      avgCycleLength: AvgCycleLengthOption.twentyOneToTwentyEight,
      periodRegularity: PeriodRegularityOption.canUsuallyPredict,
      lastPeriodDate: '2023-08-01',
      cycleChanges: [],
      statementDescription: StatementDescriptionOption.routinesAreImportant,
      sleep: SleepOption.wakeUpOften,
      sleepWithTracker: SleepWithTrackerOption.neither,
      whichWearable: [],
      consideringPurchase: ConsideringPurchaseOption.no,
      wearWatchToBed: WearWatchToBedOption.no,
    }

    const dataPlan: QuizDataInput = {
      name: 'Amalia',
      dateOfBirth: '1993-08-17',
      fertilityGoal: GoalBreakpointOption.plan,
      conceiveTiming: ConceiveTimingBreakpointOption.moreThanAYear,
      hormonalBirthControlUsage: HormonalBirthControlUsageBreakpointOption.no,
      avgCycleLength: AvgCycleLengthOption.twentyOneToTwentyEight,
      periodRegularity: PeriodRegularityOption.canUsuallyPredict,
      lastPeriodDate: '2023-08-01',
      medicalConditions: [],
      discussedFertility: DiscussedFertilityOption.noDontPlanTo,
      partnerInvolvement: PartnerInvolvementOption.noPartner,
      cycleChanges: [],
      statementDescription: StatementDescriptionOption.routinesAreImportant,
      sleep: SleepOption.wakeUpOften,
      sleepWithTracker: SleepWithTrackerOption.neither,
      whichWearable: [],
      consideringPurchase: ConsideringPurchaseOption.no,
      wearWatchToBed: WearWatchToBedOption.no,
    }

    const dataPostPartum: QuizDataInput = {
      name: 'Amalia',
      dateOfBirth: '1993-08-17',
      fertilityGoal: GoalBreakpointOption.postPartum,
    }

    const dataTrackPregnancy: QuizDataInput = {
      name: 'Amalia',
      dateOfBirth: '1993-08-17',
      fertilityGoal: GoalBreakpointOption.trackPregnancy,
    }

    const data: Record<Exclude<Goal, Goal.RECOVERY>, QuizDataInput> = {
      [Goal.PREVENT]: dataPrevent,
      [Goal.PLAN]: dataPlan,
      [Goal.POSTPARTUM]: dataPostPartum,
      [Goal.PREGNANT]: dataTrackPregnancy,
    }

    dispatch('updateQuizData', data[goal])

    await di.get(NavController).navigateForward(ROUTES.AuthPage, {
      state: { [NavigationParams.AUTH_PAGE_MODE]: AuthPageMode.Signup },
    })
    return
  }
}
