import { accountSharedUtil } from '@naturalcycles/shared'
import { isAnyAb270Assignment } from '@src/ab270/ab270'
import { isAnyAb272Assignment } from '@src/ab272/ab272'
import { isIOSApp } from '../cnst/userDevice.cnst'
import { getState } from '../srv/store.service'

export const shouldVerifyEmailOnWeb = (): boolean => {
  const { account, remoteConfig, sessionId } = getState()
  const isEmailHidden = account.email && accountSharedUtil.isEmailHidden(account.email)

  return !!(
    remoteConfig.emailVerificationOnWebEnabled &&
    sessionId &&
    !isEmailHidden &&
    isIOSApp &&
    !isAnyAb270Assignment() &&
    !isAnyAb272Assignment()
  )
}
