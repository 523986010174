import { inject, Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { select } from '@app/srv/store.service'
import { IsoDateString } from '@naturalcycles/js-lib'
import {
  AppTracker,
  BackendResponseFM,
  BackendResponseFMResp,
  EmailInput,
  PartnerAccountFM,
  PartnerAccountPatchInput,
  PartnerInitInput,
  PartnerLoginInput,
  PartnerRemoveInput,
} from '@naturalcycles/shared'
import { Observable } from 'rxjs'
import { combineLatestWith, map } from 'rxjs/operators'
import { AccountService } from './account.service'
import { userDeviceDone } from './milestones'
import { sessionSigningService } from './sessionSigning.service'
import { dispatch, getState } from './store.service'

export interface PartnersCombinedListSchema {
  email: string
  lastActiveDate?: IsoDateString
  id?: string
}

@Injectable({ providedIn: 'root' })
export class PartnerService {
  private accountService = inject(AccountService)

  @select(['userSettings', 'invitedPartnerEmails'])
  private _invitedPartnerEmails$!: Observable<string[] | undefined>

  @select(['partners'])
  private _partners$!: Observable<PartnerAccountFM[]>

  public partners$: Observable<PartnersCombinedListSchema[]> = this._invitedPartnerEmails$.pipe(
    combineLatestWith(this._partners$),
    map(([invited = [], partners = []]) => {
      const invitedEmails = invited.filter(email => {
        if (!partners.some(p => p.email === email)) return true

        dispatch('removePartnerEmail', email)
        return false
      })

      return [...partners, ...invitedEmails.map(email => ({ email }))]
    }),
  )

  public get showAddedToday(): boolean {
    const { appSettings } = getState()

    if (!appSettings) return false

    const { partnerTrackers } = appSettings

    // The trackers that are displayed in trackers-today.component
    return (
      partnerTrackers[AppTracker.MOOD] ||
      partnerTrackers[AppTracker.NOTES] ||
      partnerTrackers[AppTracker.PAIN] ||
      partnerTrackers[AppTracker.SKIN] ||
      partnerTrackers[AppTracker.LIBIDO] ||
      partnerTrackers[AppTracker.SLEEP]
    )
  }

  public async partnerInit(): Promise<BackendResponseFM> {
    await userDeviceDone

    const { userDevice, glossary } = getState()

    return await api
      .put<BackendResponseFMResp>('partners/init', {
        json: {
          userDevice,
          glossaryHash: glossary?.glossaryHash,
        } satisfies PartnerInitInput,
      })
      .then(r => r.backendResponse)
  }

  async patchPartner(input: PartnerAccountPatchInput): Promise<BackendResponseFM> {
    return await api
      .patch<BackendResponseFMResp>('partners/account', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  public async invitePartner(email: string): Promise<BackendResponseFM> {
    const backendResponse = await api
      .post<BackendResponseFMResp>('partners', {
        json: { email } satisfies EmailInput,
      })
      .then(r => r?.backendResponse)

    dispatch('addPartnerEmail', email)

    return backendResponse
  }

  public async removePartner(partnerId: string): Promise<BackendResponseFM> {
    return await api
      .put<BackendResponseFMResp>('partners/remove', {
        json: { partnerId } satisfies PartnerRemoveInput,
      })
      .then(r => r?.backendResponse)
  }

  public async getPartners(): Promise<BackendResponseFM> {
    return await api.get<BackendResponseFMResp>('partners').then(r => r.backendResponse)
  }

  public async partnerLogin(input: PartnerLoginInput): Promise<BackendResponseFM> {
    input.publicKey ||= await sessionSigningService.getPublicKey()

    return await api
      .post<BackendResponseFMResp>('partners/login', {
        json: input,
      })
      .then(r => r.backendResponse)
  }

  public async enablePartners(): Promise<BackendResponseFM> {
    return await this.accountService.partnersEnable()
  }

  public async disablePartners(): Promise<BackendResponseFM> {
    const backendResponse = await this.accountService.partnersDisable()

    dispatch('extendUserSettings', { invitedPartnerEmails: undefined })

    return backendResponse
  }
}
