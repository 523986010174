import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { optimoveService } from '@app/analytics/optimove'
import { LinkSource } from '@app/cnst/nav.cnst'
import { logUtil } from '@app/util/log.util'
import { App } from '@capacitor/app'
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications'
import { _Memo, _stringify } from '@naturalcycles/js-lib'
import { HardwareId, ReminderKey } from '@naturalcycles/shared'
import { isNativeApp } from '../cnst/userDevice.cnst'
import { AppService } from './app.service'
import { InAppBrowserService } from './inappbrowser.service'
import { NavService } from './nav.service'
import { dispatch, getState } from './store.service'
import { UserDeviceService } from './userDevice.service'

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
  private analyticsService = inject(AnalyticsService)
  private appService = inject(AppService)
  private inAppBrowserService = inject(InAppBrowserService)
  private navService = inject(NavService)
  private userDeviceService = inject(UserDeviceService)

  /**
   * Called either after app init or after giving notification permission
   * Should only be called once
   */
  @_Memo()
  public addListeners(): void {
    if (!isNativeApp) return

    void PushNotifications.removeAllListeners()

    void PushNotifications.addListener('registration', (token: Token) => {
      this.onRegistration(token.value)
    })

    void PushNotifications.addListener('registrationError', (err: any) => {
      this.onError(err)
    })

    void PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        logUtil.log('[notifications] [push] Push notification:', JSON.stringify(notification))

        const { isActive: isAppActive } = await App.getState()
        const { account } = getState()

        void this.analyticsService.trackEvent(EVENT.NOTIFICATION_TRIGGERED, {
          type: notification.data.type,
          title: notification.title,
          body: notification.body,
          // Mixpanel doesn't treat objects nicely so we stringify it
          accountReminders: _stringify(account.reminders),
          isAppActive,
        })

        if (account.hwId === HardwareId.APPLE_WATCH) return
        if (notification.data.type !== ReminderKey.fertilityStatus) return
        void this.onFertilityStatusPush(isAppActive)
      },
    )

    void PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        void this.onNotification(notification)
      },
    )

    void PushNotifications.register()
  }

  private async onFertilityStatusPush(isAppActive: boolean): Promise<void> {
    // We will call appInit when bring the app to foreground anyway
    if (!isAppActive) return

    this.analyticsService.trackEvent(EVENT.NOTIFICATION_CALLBACK_APP_INIT)

    dispatch('setGhostLoader', true)
    // Fertility status push is triggered after algo run, so we don't need to request it again
    await this.appService.appInit()
    dispatch('setGhostLoader', false)
  }

  private async onNotification(action: ActionPerformed): Promise<void> {
    logUtil.log('[notifications] [push] Push notification:', JSON.stringify(action))

    const { notification } = action
    let { type, url, link } = notification.data

    // to support old push notification format, pre v3.5.0
    if (link) {
      url = link.url
    }

    if (url) {
      dispatch('setBlockAutoOpen', true)

      const navigated = await this.navService.processInternalLink(url, LinkSource.NOTIFICATION)

      if (!navigated) {
        void this.inAppBrowserService.open(url)
      }
    }

    void this.analyticsService.trackEvent(EVENT.NOTIFICATION_OPENED, { type })
    void optimoveService.trackPushOpen(notification.data)
  }

  private onRegistration(token: string): void {
    logUtil.log('[notifications] [push] Register device token:', token)

    void this.userDeviceService.registerDeviceToken(token)
  }

  private onError(err: any): void {
    logUtil.log('Error with Push plugin')
    logUtil.error(err)
  }
}
