import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { ROUTES } from '@app/cnst/nav.cnst'
import { ConnectedThermometerGuard } from '@app/guards/connected-thermometer.guard'
import { AddDataPage } from '@app/pages/add-data/add-data.page'
import { AddDataPageGuard } from '@app/pages/add-data/add-data.page.guard'
import { AddDataPageModule } from '@app/pages/add-data/add-data.page.module'
import { AuthPageGuard } from '@app/pages/auth/auth.page.guard'
import { CalibratePageGuard } from '@app/pages/calibrate/calibrate.page.guard'
import { CardsPageGuard } from '@app/pages/cards/cards.page.guard'
import { ConsentPageGuard } from '@app/pages/consent/consent.page.guard'
import { DeleteAccountPageGuard } from '@app/pages/delete-account/delete-acconut.page.guard'
import { ErrorPageGuard } from '@app/pages/error/error.page.guard'
import { GlossaryItemPageGuard } from '@app/pages/glossary-item/glossary-item.page.guard'
import { GraphPageGuard } from '@app/pages/graph/graph.page.guard'
import { GuidePageGuard } from '@app/pages/guide/guide.page.guard'
import { HomePageGuard } from '@app/pages/home/home.page.guard'
import { IntroPageGuard } from '@app/pages/intro/intro.page.guard'
import { OrderPageGuard } from '@app/pages/order/order.page.guard'
import { PartnerViewGuard } from '@app/pages/partner/partner.page.guard'
import { PartnerLoginPageGuard } from '@app/pages/partner-login/partner-login.page.guard'
import { PaymentPageGuard } from '@app/pages/payment/payment.page.guard'
import { QuizRoutingModule } from '@app/quiz-routing.module'
import { SignupRoutingModule } from '@app/signup-routing.module'
import { NavigationParams } from './cnst/nav.params.cnst'
import { AddressPageGuard } from './pages/address/address.page.guard'
import { FlowRoutingModule } from './pages/flow/flow-routing.module'
import { OuraInfoPage } from './pages/oura/oura-info/oura-info.page'
import { ShopItemPageGuard } from './pages/shop-item/shop-item.page.guard'
import { VerifyEmailPage } from './pages/verify-email-page/verify-email-page.component'
import { VerifyEmailPageGuard } from './pages/verify-email-page/verify-email-page.guard'

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.page.router').then(m => m.homeRoutes),
    canActivate: [HomePageGuard],
    // // canLoad: [HomePageGuard],
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.page.module').then(m => m.IntroPageModule),
    canActivate: [IntroPageGuard],
    // canLoad: [IntroPageGuard],
  },

  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.page.module').then(m => m.AuthPageModule),
    canActivate: [AuthPageGuard],
    // canLoad: [AuthPageGuard],
  },
  {
    path: 'verify-email',
    component: VerifyEmailPage,
    canActivate: [VerifyEmailPageGuard],
  },
  {
    path: 'consent',
    loadChildren: () =>
      import('./pages/consent/consent.page.module').then(m => m.ConsentPageModule),
    canActivate: [ConsentPageGuard],
    // canLoad: [ConsentPageGuard],
  },
  {
    path: 'two-factor-authentication',
    loadChildren: () =>
      import('./pages/auth-2fa/auth-2fa.page.module').then(m => m.Auth2FAPageModule),
    canActivate: [AuthPageGuard],
  },
  {
    path: 'graph',
    loadChildren: () => import('./pages/graph/graph.page.module').then(m => m.GraphPageModule),
    canActivate: [GraphPageGuard],
  },
  {
    path: 'add-data',
    component: AddDataPage,
    canActivate: [AddDataPageGuard],
  },
  {
    path: 'add-data',
    outlet: 'morning',
    component: AddDataPage,
    canActivate: [AddDataPageGuard],
  },
  {
    path: `add-data/:${[NavigationParams.DATE]}`,
    component: AddDataPage,
    canActivate: [AddDataPageGuard],
  },
  {
    path: 'guide/battery-replacement',
    loadChildren: () =>
      import('./pages/guide/battery-replacement/battery-replacement-guide.page.module').then(
        m => m.BatteryReplacementGuidePageModule,
      ),
    canActivate: [ConnectedThermometerGuard],
  },
  {
    path: 'guide/:id',
    loadChildren: () => import('./pages/guide/guide.page.module').then(m => m.GuidePageModule),
    canActivate: [GuidePageGuard],
  },
  {
    path: 'calibrate',
    loadChildren: () =>
      import('./pages/calibrate/calibrate.page.module').then(m => m.CalibratePageModule),
    canActivate: [HomePageGuard, CalibratePageGuard],
  },
  {
    path: 'history',
    loadComponent: () => import('./pages/history/history.page').then(m => m.HistoryPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'pregnancy-dates',
    loadChildren: () =>
      import('./pages/pregnancy-dates/pregnancy-dates.page.module').then(
        m => m.PregnancyDatesPageModule,
      ),
    canActivate: [HomePageGuard],
  },
  {
    path: 'insights',
    loadChildren: () =>
      import('./pages/insights/insights.page.router').then(m => m.insightPageRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'pregnancy-calendar',
    loadComponent: () => import('./pages/home/month/month.page').then(m => m.MonthPage),
    canActivate: [HomePageGuard],
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./pages/checkout/checkout.page.module').then(m => m.CheckoutPageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop.page.module').then(m => m.ShopPageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'shop/:id',
    loadChildren: () =>
      import('./pages/shop-item/shop-item.page.module').then(m => m.ShopItemPageModule),
    canActivate: [ShopItemPageGuard],
  },
  {
    path: 'thermometer',
    loadChildren: () =>
      import('./pages/thermometer/thermometer.page.module').then(m => m.ThermometerPageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'oura-info',
    component: OuraInfoPage,
    canActivate: [HomePageGuard],
  },
  {
    path: 'glossary/:id',
    loadChildren: () =>
      import('./pages/glossary-item/glossary-item.page.module').then(m => m.GlossaryItemPageModule),
    canActivate: [GlossaryItemPageGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.page.module').then(m => m.ErrorPageModule),
    canActivate: [ErrorPageGuard],
  },
  {
    path: 'address',
    loadComponent: () => import('./pages/address/address.page').then(m => m.AddressPage),
    canActivate: [AddressPageGuard],
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment/payment.page.module').then(m => m.PaymentPageModule),
    canActivate: [PaymentPageGuard],
  },
  {
    path: 'payment/:mode',
    loadChildren: () =>
      import('./pages/payment/payment.page.module').then(m => m.PaymentPageModule),
    canActivate: [PaymentPageGuard],
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.page.module').then(m => m.OrderPageModule),
    canActivate: [OrderPageGuard],
  },
  {
    path: 'subscriptions-and-payments/manage',
    loadChildren: () =>
      import('./pages/manage-account/manage-account.page.module').then(
        m => m.ManageAccountPageModule,
      ),
    canActivate: [HomePageGuard],
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.page.module').then(m => m.SupportPageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'partner-login',
    loadChildren: () =>
      import('./pages/partner-login/partner-login.page.module').then(m => m.PartnerLoginPageModule),
    canActivate: [PartnerLoginPageGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.page.module').then(m => m.ProfilePageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.router').then(r => r.settingsRoutes),
    canActivate: [HomePageGuard],
  },
  {
    path: 'my-device',
    loadChildren: () =>
      import('./pages/my-device/my-device.page.module').then(m => m.MyDevicePageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'refer',
    loadChildren: () => import('./pages/refer/refer.page.module').then(m => m.ReferPageModule),
    canActivate: [HomePageGuard],
  },
  {
    path: 'regulatory',
    loadChildren: () =>
      import('./pages/regulatory/regulatory.page.module').then(m => m.RegulatoryPageModule),
  },
  {
    path: 'partner',
    loadChildren: () => import('./pages/partner/partner.page.router').then(m => m.partnerRoutes),
    canActivate: [PartnerViewGuard],
  },
  {
    path: 'delete-account',
    loadChildren: () =>
      import('./pages/delete-account/delete-account.page.module').then(
        m => m.DeleteAccountPageModule,
      ),
    canActivate: [DeleteAccountPageGuard],
  },
  {
    path: 'deleted-account',
    loadChildren: () =>
      import('./pages/deleted-account/deleted-account.page.module').then(
        m => m.DeletedAccountPageModule,
      ),
    canActivate: [DeleteAccountPageGuard],
  },
  {
    path: 'cards/:id',
    loadChildren: () => import('./pages/cards/cards.page.module').then(m => m.CardsPageModule),
    canActivate: [CardsPageGuard],
  },
  {
    path: 'mens-calendar',
    loadComponent: () =>
      import('./pages/mens-calendar/mens-calendar.page').then(m => m.MensCalendarPage),
    canActivate: [HomePageGuard],
  },

  // redirect pre-v4.1.0 routes
  {
    path: 'reminders',
    redirectTo: ROUTES.SettingsRemindersPage,
  },
  { path: '**', redirectTo: '/(morning:add-data)' },
  // { path: '**', redirectTo: 'home' },
  // { path: '**', redirectTo: '/intro' },
]

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy }),
    FlowRoutingModule,
    SignupRoutingModule,
    AddDataPageModule,
    QuizRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
