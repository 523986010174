import { Injectable } from '@angular/core'
import { api } from '@app/srv/api.service'
import { BackendResponseFMResp, Order, OrderInput } from '@naturalcycles/shared'
import { ReplaySubject } from 'rxjs'

export enum SubscriptionState {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  RENEWABLE = 'RENEWABLE',
  NONRENEWABLE = 'NONRENEWABLE',
  EXPIRED = 'EXPIRED',
}

@Injectable({ providedIn: 'root' })
export class OrderService {
  public redDotNonce$ = new ReplaySubject<string>()

  public async placeOrder(input: OrderInput): Promise<Order> {
    return await api
      .post<BackendResponseFMResp>('orders', {
        json: input,
      })
      .then(r => r.backendResponse.order!)
  }
}
