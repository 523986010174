<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-back-button mode="md" icon="close" [defaultHref]="ROUTES.ShopPage" />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <h1>{{ 'info-ouraLearnMore-title' | tr }}</h1>
  <img src="/assets/img/nc_oura.jpg" [alt]="'cart-OURA_RING' | tr" />
  <div tmd="info-ouraLearnMore-body"></div>
</ion-content>

<ion-footer>
  @if (!!(currentSubscription$ | async)) {
    <ion-button uid="ouraInfo__confirm" expand="block" shape="round" (click)="claimDiscount()">
      <ion-label> {{ 'info-ouraLearnMore-btn' | tr }} </ion-label>
    </ion-button>
  } @else {
    <ion-button uid="ouraInfo__manage" expand="block" shape="round" (click)="manage()">
      <ion-label> {{ 'manage-subscription' | tr }} </ion-label>
    </ion-button>
  }
</ion-footer>
