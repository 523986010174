<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="close()" uid="addData__close" aria-label="Close">
        <ion-icon slot="icon-only" name="close" aria-hidden="true" />
      </ion-button>
    </ion-buttons>

    <ion-title uid="addData__title">{{ formattedDate$ | async }}</ion-title>

    <ion-buttons slot="end">
      <ion-button
        mode="md"
        uid="addData__buttonPrevious"
        (click)="slidePrev()"
        [attr.aria-label]="'txt-slide-to-previous-day' | tr"
      >
        <ion-icon [src]="ICON.ARROW_LEFT" slot="icon-only" aria-hidden="true" />
      </ion-button>
      <ion-button
        mode="md"
        uid="addData__buttonNext"
        [disabled]="this.isToday"
        (click)="slideNext()"
        [attr.aria-label]="'txt-slide-to-next-day' | tr"
      >
        <ion-icon [src]="ICON.ARROW_RIGHT" slot="icon-only" aria-hidden="true" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

@if (activeTour$ | async; as tour) {
  <app-tour-navbar
    [totalSteps]="tour.steps"
    [currentStep]="tour.currentStep"
    [ctaCenter]="tour.ctaCenter"
    (nextClick)="tourService.nextStep()"
    (endTour)="tourService.endTour($event)"
  />
}

<ion-content
  uid="addData__page"
  class="addData__content"
  [scrollEvents]="true"
  [scrollY]="lockScroll$ | async"
  (ionScroll)="onScroll($any($event))"
>
  @if (config$ | async; as config) {
    <div>
      @if (showInAppTourDoneTooltip$ | async) {
        <span
          style="position: absolute; width: 100%"
          tooltip="app-tour-add-data"
          [tooltipOpen]="true"
        ></span>
      }
      @if (
        {
          visible: !(ghostVisible$ | async),
          hwId: hwId$ | async,
          temperatureState: temperatureState$ | async,
        };
        as observables
      ) {
        <span
          #slides
          class="addData__slides"
          [style.transform]="'translateX(' + (translateX$ | async) + 'px)'"
          [@slideAnimation]="slidesState$ | async"
          (@slideAnimation.done)="slideDidChange($event)"
        >
          <div class="addData__slide addData__slide--yesterday">
            @if (yesterdayTemperature; as temperature) {
              <div class="addData__header">
                <app-temperature-display
                  class="addData__temperatureDisplay"
                  [hwId]="observables.hwId"
                  [digits]="temperature.digits"
                  [isDeviating]="temperature.deviating"
                  [state]="temperature.temperatureState?.state"
                  [isDisabled]="
                    observables.hwId === HardwareId.APPLE_WATCH ||
                    observables.hwId === HardwareId.OURA
                  "
                />
              </div>
            }
            <ng-container *ngTemplateOutlet="addDataGhost" />
          </div>
          <div class="addData__slide addData__slide--main">
            <div class="addData__header">
              <app-nav-banner class="addData__warningHeader" [criticalOnly]="true" />
              <app-temperature-display
                class="addData__temperatureDisplay"
                uid="addData__temperatureDisplay"
                rowUid="addData__temperatureDisplay__row"
                [hwId]="observables.hwId"
                [digits]="selectedDigits"
                [isFocused]="numpadActive$ | async"
                [isDeviating]="isDeviating"
                [state]="observables.temperatureState?.state"
                [timestamp]="observables.temperatureState?.formattedTimestamp"
                [isDisabled]="
                  observables.hwId === HardwareId.APPLE_WATCH ||
                  observables.hwId === HardwareId.OURA
                "
                (click)="onTemperatureDisplayClick()"
                (temperatureStateClick)="onTemperatureStateClick($event)"
              />
              <div
                [tour]="TourTooltip.APP_6_1"
                style="position: absolute; left: 50%; margin-top: -24px"
                tooltipPosition="bottom"
              ></div>
            </div>
            @if (showPregEndTooltip$ | async) {
              <span
                style="position: absolute; width: 100%; transform: translateY(80px)"
                tooltip="recovery-tour-tooltip-4"
                [tooltipOpen]="true"
              ></span>
            }
            <div
              [tour]="TourTooltip.POSTPARTUM_6_1"
              style="position: absolute; left: 50%; margin-top: 76px"
            ></div>
            @if (entry) {
              <app-add-data-additional-info
                class="addData__additionalInfo"
                [entry]="entry"
                [config]="config"
                [ouraMode]="observables.hwId === HardwareId.OURA"
                [downButtonRotated]="scrollDownButtonRotated"
                [showScrollDownButton]="showScrollDownButton"
                [showAllTrackers]="observables.visible"
                [ready]="ready"
                (changes)="additionalDataChange($event)"
                (noLongerPregnant)="onNoLongerPregnant($event)"
                (scrollDownClick)="scrollButtonClicked()"
                (additionalDataLoaded)="onAdditionalDataLoaded()"
                (scrollLock)="onScrollLock($event)"
              />
            }
            <!-- Replace @fadeAnimation with .animated if/when additionalInfo is inlined -->
            @if (isAdditionalDataLoaded$ | async) {
              @if (config.showSensitiveTrackers) {
                <ion-button
                  [@fadeAnimation]
                  uid="addData__sensitiveTrackers__button"
                  class="addData__extraButton"
                  [class.addData__extraButton--addedData]="hasSensitiveData"
                  fill="solid"
                  expand="block"
                  shape="round"
                  (click)="openSensitiveTrackers()"
                >
                  @if (config.pregnantDay) {
                    <ion-label>
                      {{ 'add-data-more-pregnancy-options' | tr }}
                    </ion-label>
                  } @else {
                    <ion-label>{{ 'add-data-more-sensitive-trackers' | tr }}</ion-label>
                  }
                  <ion-icon
                    class="addData__sensitiveTrackersIcon"
                    color="white"
                    [src]="ICON.CHECK"
                    aria-hidden="true"
                  />
                </ion-button>
              }
              <ion-button
                [@fadeAnimation]
                class="addData__extraButton"
                fill="solid"
                expand="block"
                shape="round"
                (click)="openTrackerSettings()"
              >
                <ion-icon [src]="ICON.SETTINGS" aria-hidden="true" />
                <ion-label>{{ 'txt-manage-tracker-settings' | tr }}</ion-label>
              </ion-button>
            }
          </div>
          @if (!isToday && observables.visible) {
            <div class="addData__slide addData__slide--tomorrow">
              @if (tomorrowTemperature; as temperature) {
                <div class="addData__header">
                  <app-nav-banner class="addData__warningHeader" [criticalOnly]="true" />
                  <app-temperature-display
                    class="addData__temperatureDisplay"
                    [hwId]="observables.hwId"
                    [digits]="temperature.digits"
                    [isDeviating]="temperature.deviating"
                    [state]="temperature.temperatureState?.state"
                    [isDisabled]="
                      observables.hwId === HardwareId.APPLE_WATCH ||
                      observables.hwId === HardwareId.OURA
                    "
                  />
                </div>
              }
              <ng-container *ngTemplateOutlet="addDataGhost" />
            </div>
          }
        </span>
      }
      <ng-template #addDataGhost>
        <div class="addData__additionalInfoGhost">
          @if (config.deviationReasonFlags?.length; as deviationReasonsLen) {
            <div
              class="addData__additionalInfoGhost--deviationSegment"
              [style.width.px]="deviationReasonsLen * 68"
            ></div>
          }
          @if (config.showNoLongerPregnant) {
            <div
              class="addData__additionalInfoGhost--segment addData__additionalInfoGhost__noLongerPregnant"
            ></div>
          }
          @if (config.mensSegments?.length; as mensSegmentsLen) {
            <div
              class="addData__additionalInfoGhost--segment"
              [style.width.px]="mensSegmentsLen * AVG_SEGMENT_WIDTH"
            ></div>
          }
          @if (config.sexSegments?.length; as sexSegmentsLen) {
            <div
              class="addData__additionalInfoGhost--segment"
              [style.width.px]="sexSegmentsLen * AVG_SEGMENT_WIDTH"
            ></div>
          }
          @if (config.lhSegments?.length; as lhSegmentsLen) {
            <div
              class="addData__additionalInfoGhost--segment"
              [style.width.px]="lhSegmentsLen * AVG_SEGMENT_WIDTH"
            ></div>
          }
          @if (config.pregTestSegments?.length; as pregTestSegmentsLen) {
            <div
              class="addData__additionalInfoGhost--segment"
              [style.width.px]="pregTestSegmentsLen * AVG_SEGMENT_WIDTH"
            ></div>
          }
          @if (
            config.cmSegments ||
            config.cmQuantitySegments ||
            config.cmConsistencySegments ||
            config.skinFlags ||
            config.painFlags
          ) {
            <div class="addData__additionalInfoGhost--circle"></div>
          }
        </div>
      </ng-template>
      @if (!tourIsActive) {
        <div class="addData__footer">
          <ion-toolbar class="addData__footerBar">
            @if (showScrollDownButton) {
              <ion-buttons slot="start">
                <ion-button
                  uid="addData__footer__scroll__button"
                  class="addData__downButton"
                  [class.addData__downButton--rotated]="scrollDownButtonRotated"
                  color="purple"
                  shape="round"
                  fill="solid"
                  (click)="scrollButtonClicked()"
                  [attr.aria-label]="'txt-scroll-down' | tr"
                >
                  <ion-icon [src]="ICON.ARROW_DOWN" slot="icon-only" aria-hidden="true" />
                </ion-button>
              </ion-buttons>
            }
            <ion-buttons slot="end">
              @if (_hasDataToClear) {
                <ion-button
                  uid="addData__footer__clear__button"
                  class="addData__footer__button addData__footer__button--clear"
                  color="purple"
                  shape="round"
                  fill="outline"
                  size="small"
                  (click)="onClearButtonClick()"
                >
                  <ion-label>{{ 'txt-clear' | tr }}</ion-label>
                </ion-button>
              }
              <ion-button
                [attr.uid]="
                  _showSkipButton
                    ? 'addData__footer__skip__button'
                    : 'addData__footer__save__button'
                "
                class="addData__footer__button"
                shape="round"
                color="purple"
                fill="solid"
                size="small"
                (click)="onSubmitButtonClick(_showSkipButton)"
                loader
                haptic
              >
                <ion-label>{{
                  (_showSkipButton ? 'add-data-skip-today' : 'txt-save') | tr
                }}</ion-label>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </div>
      }
    </div>
  }
</ion-content>

<ion-footer>
  @if (numpadActive$ | async) {
    <app-temperature-numpad
      uid="addData__temperature-numpad"
      class="addData__numpad"
      [digits]="selectedDigits"
      (digitsChange)="selectedDigits = $event"
      (temperatureChange)="onTemperatureChange('keyboard', $event)"
      (dismiss)="onNumpadDismiss()"
      [@numpadAnimation]
      (@numpadAnimation.done)="onNumpadAnimationDone()"
    />
  }
</ion-footer>
