import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppPreloadingStrategy } from '@app/app.preloading.strategy'
import { PlansPageGuard } from '@app/pages/plans/plans.page.guard'
import { MeasuringDevicePageGuardAb270 } from '@src/ab270/measuring-device-page-ab270/measuring-device-ab270.guard'
import { AddressPageGuard } from './pages/address/address.page.guard'
import { MeasuringDevicePageGuard } from './pages/measuring-device/measuring-device.guard'

const routes: Routes = [
  {
    path: 'signup',
    children: [
      {
        path: 'measuring-device',
        loadComponent: () =>
          import('./pages/measuring-device/measuring-device.page').then(m => m.MeasuringDevicePage),
        canActivate: [MeasuringDevicePageGuard],
      },

      // AB270
      {
        path: 'measuring-device-270',
        loadComponent: () =>
          import('../ab270/measuring-device-page-ab270/measuring-device-ab270.page').then(
            m => m.MeasuringDeviceAb270Page,
          ),
        canActivate: [MeasuringDevicePageGuardAb270],
      },

      {
        path: 'plans',
        loadComponent: () => import('./pages/plans/plans.page').then(m => m.PlansPage),
        canActivate: [PlansPageGuard],
        // canLoad: [PlansPageGuard],
      },
      {
        path: 'address',
        loadComponent: () => import('./pages/address/address.page').then(m => m.AddressPage),
        canActivate: [AddressPageGuard],
      },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy }),
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class SignupRoutingModule {}
