import { Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { ICON } from '@app/cnst/icons.cnst'
import { IonicModule } from '@ionic/angular'
import { QuizProgressIndicatorComponent } from '@src/app/cmp/quiz/progress-indicator/progress-indicator.component'
import { ROUTES } from '@src/app/cnst/nav.cnst'
import { quizParentSlug } from '@src/app/cnst/quiz.cnst'
import { SharedModule } from '@src/app/shared.module'
import { QuizService } from '@src/app/srv/quiz/quiz.service'
import { getState } from '@src/app/srv/store.service'

@Component({
  selector: 'app-quiz-header',
  templateUrl: './quiz-header.component.html',
  styleUrls: ['../../../pages/quiz/quiz.base.scss', './quiz-header.component.scss'],
  imports: [IonicModule, SharedModule, QuizProgressIndicatorComponent],
  standalone: true,
})
export class QuizHeaderComponent {
  public ICON = ICON
  public ROUTES = ROUTES

  private quizService = inject(QuizService)
  private router = inject(Router)

  protected get showBackButton(): boolean {
    const { sessionId } = getState()
    if (!sessionId) return true

    const firstPage = this.quizService.getEngine().getFirstPageInFlow()
    const isFirstQuizPage = this.router.url === `/${quizParentSlug}/${firstPage}`
    return !isFirstQuizPage
  }
}
