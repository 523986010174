import { Injectable } from '@angular/core'
import { env } from '@src/environments/environment'
import { MoECapacitorCore, MoEInitConfig, MoEProperties } from 'capacitor-moengage-core'
import { isWebApp } from '../cnst/userDevice.cnst'

const appId = env.moEngageAppId
const disabled = isWebApp || !appId

@Injectable({ providedIn: 'root' })
export class MoEngageService {
  public async init(): Promise<void> {
    if (disabled) return

    const initConfig: MoEInitConfig = {
      analyticsConfig: {
        shouldTrackUserAttributeBooleanAsNumber: true,
      },
    }

    await MoECapacitorCore.initialize({ appId, initConfig })
  }

  public async setUniqueId(uniqueId: string): Promise<void> {
    if (disabled) return

    await MoECapacitorCore.setUniqueId({ uniqueId, appId })
  }

  public async trackEvent(eventName: string, eventAttributes?: MoEProperties): Promise<void> {
    if (disabled) return

    await MoECapacitorCore.trackEvent({ eventName, eventAttributes, appId })
  }

  public async resetUser(): Promise<void> {
    if (disabled) return

    await MoECapacitorCore.logoutUser({ appId })
  }
}
