@if ({ intensityValue: intensityValue$ | async }; as observables) {
  <ion-item
    [attr.uid]="'addData__' + dataFlagType + '__' + DataFlag[dataFlag]"
    color="clear"
    lines="none"
    [class]="'ion-no-padding dataFlag__item dataFlag__item--' + dataFlagType"
    [ngClass]="{
      'dataFlag__item--pregnant': dataFlag === DataFlag.PAIN_MOVEMENTS,
      'dataFlag__item--checked': checked,
    }"
    haptic
    button
    (longPressStart)="onLongPressStart()"
    (longPressMove)="onLongPressMove($event)"
    (longPressEnd)="onLongPressEnd()"
    (shortClick)="onShortClick()"
    long-press
  >
    @if (checked && observables.intensityValue) {
      <div class="innerIntensity">
        @for (intensity of intensities; track $index) {
          <span
            class="innerIntensity__element"
            [class.innerIntensity__element--active]="
              observables.intensityValue && observables.intensityValue >= intensity
            "
          ></span>
        }
      </div>
    }
    <ion-checkbox
      [id]="'addData__' + dataFlagType + '__' + DataFlag[dataFlag]"
      class="dataFlag__checkbox"
      [checked]="checked"
      disabled
      [attr.aria-label]="'txt-dataflag--' + DataFlag[dataFlag] | tr"
    />
    <ion-icon slot="start" [src]="ICON_BY_DATAFLAG[dataFlag]" aria-hidden="true" />
    <div class="dataFlag__title">
      @if (dataFlag !== DataFlag.PAIN_OTHER) {
        <span>{{ 'txt-dataflag--' + DataFlag[dataFlag] | tr }}</span>
      } @else {
        <span>{{ 'txt-dataflag--' + DataFlag[dataFlag] + '--short' | tr }}</span>
      }
    </div>
    @if (showIntensityBar$ | async) {
      <div class="intensityBar">
        @for (intensity of intensities; track $index) {
          <span
            class="intensityBar__progress"
            [class.intensityBar__progress--active]="
              observables.intensityValue && observables.intensityValue >= intensity
            "
          ></span>
        }
      </div>
    }
  </ion-item>
}
