<ul class="quizSelection" [class.quizSelection___twoColumns]="twoColumns">
  @for (selection of selections; track selection.key) {
    <li
      class="quizSelection__selection"
      [attr.uid]="'quiz-option-' + selection.key"
      [class.quizSelection__selection___isMultiSelect]="isMultiSelect"
      [class.quizSelection__selection___withRightImg]="selection.imgPosition === 'right'"
      [class.quizSelection__selection___selected]="
        (selectedKey && !isMultiSelect && selectedKey === selection.key) ||
        (isMultiSelect && selectedKeys?.includes(selection.key))
      "
      (click)="select(selection.key)"
      tabindex="0"
    >
      @if (selection.img) {
        <div class="quizSelection__img">
          <img
            src="{{ selection.img }}"
            class="quizSelection__icon"
            [class.quizSelection__icon___right]="selection.imgPosition === 'right'"
            [alt]=""
          />
        </div>
      }
      <div
        class="quizSelection__text"
        [class.quizSelection__text___center]="selection.img && selection.imgPosition !== 'right'"
      >
        {{ selection.textKey | tr }}
      </div>
      @if (isMultiSelect) {
        <div class="quizSelection__addIcon">
          <ion-icon
            [icon]="selectedKeys?.includes(selection.key) ? ICON.CHECK : ICON.ADD"
            color="purple"
          />
        </div>
      }
    </li>
  }
</ul>
@if (isMultiSelect && selectedKeys?.length) {
  <ion-button
    expand="block"
    shape="round"
    [attr.uid]="'selection-continue-btn'"
    [attr.aria-label]="'delivery-btn-next' | tr"
    (click)="continue()"
  >
    <ion-label>
      {{ 'delivery-btn-next' | tr }}
    </ion-label>
  </ion-button>
}
