import { Component, inject, Input, output } from '@angular/core'
import { addAnalyticsProps } from '@app/analytics/analytics.service'
import { ICON } from '@app/cnst/icons.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { InfoModal } from '@app/modals/info/info.modal'
import { Message } from '@app/model/message.model'
import { HardwareDevice } from '@app/reducers/hardwareDevice.reducer'
import { UI } from '@app/reducers/ui.reducer'
import { HardwareDeviceService } from '@app/srv/hardwareDevice.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { select2 } from '@app/srv/store.service'
import { TourService } from '@app/srv/tour.service'
import { tr } from '@app/srv/translation.util'
import { UFService } from '@app/srv/uf.service'
import { NavController } from '@ionic/angular'
import { IsoDateString } from '@naturalcycles/js-lib'
import {
  AccountTM,
  BatteryStatus,
  FWVersion,
  FWVersionSeverity,
  HardwareId,
  OuraState,
  ProductKey,
  ShippingItemFM,
  SubscriptionFM,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { combineLatestWith, map } from 'rxjs/operators'

interface Banner {
  title: string
  icon?: ICON
  onClick?: () => void
}

@Component({
  selector: 'app-nav-banner',
  templateUrl: 'nav-banner.component.html',
  styleUrls: ['nav-banner.component.scss'],
})
export class NavBannerComponent {
  private popupController = inject(PopupController)
  private navController = inject(NavController)
  private ufService = inject(UFService)
  private tourService = inject(TourService)
  private hardwareDeviceService = inject(HardwareDeviceService)

  @Input()
  public criticalOnly = false

  public visible = output<boolean>()

  private account$ = select2(s => s.account)
  private ui$ = select2(s => s.ui)
  private currentSubscription$ = select2(s => s.subscriptions.current)
  private hardwareDevice$ = select2(s => s.hwDevice)
  private ouraState$ = select2(s => s.oura)
  private msgs$ = select2(s => s.messages.messages)
  private t2SentDate$ = select2(s => s.account.t2SentDate)
  private latestFWVersion$ = select2(s => s.latestHWDeviceFWVersion)
  public hasRevokedAppleWatchPermissions$ = select2(
    s => s.userSettings.hasRevokedAppleWatchPermissions,
  )
  public hasCompatibleAppleWatch$ = select2(s => s.userSettings.hasCompatibleAppleWatch)
  public regHWId$ = select2(s => s.account.onboardingData?.regHWId)
  public replacementShippingItem$ = select2(s => s.replacementShippingItem)

  public ICON = ICON

  public banner$ = this.account$.pipe(
    combineLatestWith(
      this.ui$,
      this.currentSubscription$,
      this.hardwareDevice$.pipe(
        combineLatestWith(
          this.latestFWVersion$,
          this.ouraState$,
          this.ufService.showFertilityStatus$,
          this.tourService.activeTour$,
        ),
      ),
      this.msgs$.pipe(combineLatestWith(this.t2SentDate$)),
      this.hardwareDeviceService.hardwareDevice$,
      this.hasRevokedAppleWatchPermissions$,
      this.hasCompatibleAppleWatch$,
      this.regHWId$,
      this.replacementShippingItem$,
    ),
    map(
      ([
        account,
        ui,
        subscription,
        [hwDevice, latestFWVersion, ouraState, showFertilityStatus, activeTour],
        [msgs, t2SentDate],
        { batteryStatus, fwVersion },
        hasRevokedAppleWatchPermissions,
        hasCompatibleAppleWatch,
        regHWId,
        replacementShippingItem,
      ]) => {
        const banner = this.getBanner(
          account,
          ui,
          subscription,
          hwDevice,
          latestFWVersion,
          fwVersion,
          ouraState,
          showFertilityStatus,
          !!activeTour,
          msgs,
          regHWId,
          t2SentDate,
          batteryStatus,
          hasRevokedAppleWatchPermissions,
          hasCompatibleAppleWatch,
          replacementShippingItem,
        )

        this.visible.emit(!!banner)

        return banner
      },
    ),
  )

  private getBanner(
    account: AccountTM,
    ui: UI,
    currentSubscription: SubscriptionFM | null,
    hwDevice: HardwareDevice | null,
    latestFWVersion: FWVersion | null,
    currentFWVersion: string | undefined,
    ouraState: OuraState | null,
    showFertilityStatus: boolean,
    activeTour: boolean,
    msgs: Message[],
    regHWId: HardwareId | undefined,
    t2SentDate: IsoDateString | undefined,
    batteryStatus: BatteryStatus | undefined,
    hasRevokedAppleWatchPermissions: boolean | undefined,
    hasCompatibleAppleWatch: boolean | null | undefined,
    replacementShippingItem: ShippingItemFM | null,
  ): Banner | undefined {
    if (this.criticalOnly) return this.getCriticalFWUpdateBanner(latestFWVersion, currentFWVersion)
    // Demo
    if (account.demoMode) {
      return {
        title: 'txt-demo-data',
      }
    }

    // Offline
    if (!ui.online) {
      return {
        title: 'txt-no-internet',
        onClick: () => this.showOfflinePopup(),
      }
    }

    // Expired subscription
    if (!ui.ghostLoader && !currentSubscription) {
      return {
        title: 'txt-no-active-subscription',
        onClick: () => this.navigateForward(ROUTES.ManageAccountPage),
      }
    }

    // Pair T2
    if (
      msgs.some(
        m => m.msgKey === 'msg-T2-reminderToPair' && m.created > dayjs().subtract(30, 'day').unix(),
      ) &&
      account.hwId !== HardwareId.UEBE_THERMOMETER &&
      t2SentDate
    ) {
      return {
        title: 'txt-pair-bluetooth-thermometer',
        onClick: () => this.navigateForward(ROUTES.MyDevicePage),
      }
    }

    // Pair T2 or T3
    if (
      (account.hwId === HardwareId.UEBE_THERMOMETER ||
        account.hwId === HardwareId.T3_THERMOMETER) &&
      !hwDevice?.mac &&
      showFertilityStatus &&
      !activeTour
    ) {
      return {
        title: 'txt-pair-bluetooth-thermometer',
        onClick: () => this.navigateForward(ROUTES.MyDevicePage),
      }
    }

    // Oura disconnected
    if (account.hwId === HardwareId.OURA && ouraState && !ouraState.authorized) {
      return {
        title: 'txt-oura-disconnected',
        onClick: () => this.navigateForward(ROUTES.MyDevicePage),
      }
    }

    // Connected thermometer - critically low battery
    if (batteryStatus === BatteryStatus.CRITICALLY_LOW) {
      return {
        title: 'txt-battery-critically-low',
        icon: ICON.BATTERY_ALERT,
        onClick: () => this.navigateForward(ROUTES.MyDevicePage),
      }
    }

    // There is a critical firmware update for thermometer
    const fwUpdateBanner = this.getCriticalFWUpdateBanner(latestFWVersion, currentFWVersion)
    if (fwUpdateBanner) return fwUpdateBanner

    // Awaiting thermometer replacement
    // NOTE: don't forget to update MyDevicePage.showReplacementPairingWarning$ if changing this
    const hasPairedBeforeReplacement =
      replacementShippingItem && (!hwDevice || hwDevice.created <= replacementShippingItem.created)
    if (account.hwId === HardwareId.T3_THERMOMETER && hasPairedBeforeReplacement) {
      const { units } = replacementShippingItem
      const route = units.some(u => u.productKey === ProductKey.T3_THERMOMETER)
        ? ROUTES.MyDevicePage
        : ROUTES.ConnectOtherDevicePage

      return {
        title: 'txt-awaiting-new-device',
        icon: ICON.WARNING,
        onClick: () => this.navigateForward(route),
      }
    }

    // User has revoked AW permissions
    if (hasRevokedAppleWatchPermissions && account.hwId === HardwareId.APPLE_WATCH) {
      return {
        title: 'aw-revoked-permissions-banner-title',
        icon: ICON.WARNING,
        onClick: () => this.showMissingPermissionsAlert(),
      }
    }

    // new AW user has incompatible device
    if (
      hasCompatibleAppleWatch === false &&
      regHWId === HardwareId.APPLE_WATCH &&
      account.hwId === HardwareId.APPLE_WATCH
    ) {
      return {
        title: 'aw-incompatible-device',
        icon: ICON.WARNING,
        onClick: () => this.navigateForward(ROUTES.ConnectOtherDevicePage),
      }
    }
  }

  private showOfflinePopup(): void {
    void this.popupController.presentModal(
      {
        component: InfoModal,
        componentProps: {
          title: 'offline-mode-modal-title',
          body: 'offline-mode-modal-body',
        },
      },
      `modal-offline-mode`,
      Priority.IMMEDIATE,
    )
  }

  private navigateForward(route: string): void {
    addAnalyticsProps({ source: 'NavBanner' })

    void this.navController.navigateForward(route, {
      state: { [NavigationParams.SOURCE_PATH]: 'NavBanner' },
    })
  }

  private getCriticalFWUpdateBanner(
    latestFWVersion: FWVersion | null,
    currentFWVersion?: string,
  ): Banner | undefined {
    if (latestFWVersion?.severity !== FWVersionSeverity.CRITICAL) return
    if (currentFWVersion && currentFWVersion === latestFWVersion.version) return
    return {
      title: 'txt-critical-firmware-update',
      icon: ICON.UPDATE,
      onClick: () => this.navigateForward(ROUTES.MyDevicePage),
    }
  }

  private async showMissingPermissionsAlert(): Promise<void> {
    const alert = await this.popupController.presentAlert(
      {
        header: tr('aw-revoked-permissions-alert-title'),
        message: tr('aw-revoked-permissions-alert-body'),
        buttons: [
          {
            text: tr('txt-understand'),
            role: 'cancel',
          },
        ],
      },
      'apple-watch-alert-revoked-permissions-from-banner',
      Priority.IMMEDIATE,
    )

    // close the modal automatically when user has confirmed permissions in the background
    // to be in line with the banner
    const sub = this.hasRevokedAppleWatchPermissions$.subscribe(hasRevoked => {
      if (!hasRevoked && this.popupController.getActivePopup()?.id === alert.id) {
        void this.popupController.dismissActive()
      }
      void alert.onWillDismiss().then(() => sub.unsubscribe())
    })
  }
}
