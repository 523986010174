import { Experiment, getBucket, Goal, ProductKey } from '@naturalcycles/shared'
import { getState } from '@src/app/srv/store.service'

export const isAb270Test = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.MOVE_HARDWARE_CHOICE_2]
  if (!assignment) return false
  return getBucket(assignment) === 'test'
}

export const isAb270Test2 = (): boolean => {
  const assignment = getState().experiment.assignments[Experiment.MOVE_HARDWARE_CHOICE_2]
  if (!assignment) return false
  return getBucket(assignment) === 'test2'
}

export const isAnyAb270Test = (): boolean => {
  return isAb270Test() || isAb270Test2()
}

export const isAnyAb270Assignment = (): boolean => {
  return !!getBucket(getState().experiment.assignments[Experiment.MOVE_HARDWARE_CHOICE_2])
}

export const hasSession = (): boolean => {
  return !!getState().sessionId
}

export const isEligibleGoal = (): boolean => {
  const { account } = getState()
  /** Users who has follow or postpartum goal have not gone through the device
   * section of the quiz and should be treated as control regardless of assigment
   */
  const ineligibleGoals = [Goal.PREGNANT, Goal.POSTPARTUM]
  // Users who registered on login page won't have a goal at this stage and will not be eligible
  const isEligible = account.goal && !ineligibleGoals.includes(account.goal)
  return !!isEligible
}

export const deviceResultTitle: Partial<Record<ProductKey, string>> = {
  [ProductKey.APPLE_WATCH]: 'Your best fit: Apple Watch',
  [ProductKey.OURA_RING_DISCOUNT]: 'Your dream device is Oura Ring',
  [ProductKey.BT_THERMOMETER]: 'Meet your measuring match:<br/>NC° Thermometer',
}

export const deviceResultSubtitle =
  'A seamless fit for your lifestyle. Change device at any time in the NC° app.'
