import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import {
  AddDataConfig,
  bleedingItems,
  cervicalMucusItems,
  emergencyItems,
  lhItems,
  libidoItems,
  otherSexItems,
  pregItems,
  sexItems,
  sleepItems,
  SuperSegmentItem,
  TemperatureState,
} from '@app/cnst/add-data.cnst'
import { HardwareType } from '@app/cnst/hardware.cnst'
import {
  MOOD_FLAGS,
  MOOD_FLAGS_FOR_PREGNANT,
  PAIN_FLAGS,
  PAIN_FLAGS_FOR_PREGNANT,
  PAIN_FLAGS_POSTPARTUM,
  SKIN_FLAGS,
  SKIN_FLAGS_POSTPARTUM,
  SKIN_FLAGS_PREGNANT,
} from '@app/cnst/trackers.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { ScienceConsentModal } from '@app/modals/science-consent/science-consent.modal'
import { UserSettings } from '@app/reducers/userSettings.reducer'
import { AccountService } from '@app/srv/account.service'
import { allTrackersOff, AppSettingsFM } from '@app/srv/appSettings.cnst'
import { SyncedTemperature } from '@app/srv/bluetooth.service'
import { DailyEntryService } from '@app/srv/dailyentry.service'
import { DateFormat, DateService } from '@app/srv/date.service'
import { di } from '@app/srv/di.service'
import { HealthKitService } from '@app/srv/healthkit/healthkit.service'
import { NetworkService } from '@app/srv/network.service'
import { NotificationService } from '@app/srv/notification.service'
import { PopupController, Priority } from '@app/srv/popup.controller'
import { dispatch, getState } from '@app/srv/store.service'
import { tr } from '@app/srv/translation.util'
import { UFService } from '@app/srv/uf.service'
import { logUtil } from '@app/util/log.util'
import {
  _deepEquals,
  _filterEmptyValues,
  _lastOrUndefined,
  _Memo,
  _omit,
  _sortBy,
  _stringMapValues,
  DateInterval,
  DeferredPromise,
  HttpRequestError,
  pDefer,
  StringMap,
} from '@naturalcycles/js-lib'
import {
  AccountDataFM,
  AccountTM,
  AppTracker,
  CervicalMucusConsistency,
  DailyEntryBM,
  DailyEntrySaveBatchInput,
  DailyEntrySaveInput,
  dailyEntrySharedUtil,
  DataFlag,
  DataQuantity,
  DEVIATION_REASON_FLAGS,
  Goal,
  HadSex,
  HardwareId,
  LANG,
  Mens,
  SEX_TYPE_PROTECTED_VALUES,
  SEX_TYPE_UNPROTECTED_VALUES,
  SexType,
} from '@naturalcycles/shared'
import { dayjs } from '@naturalcycles/time-lib'
import { firstValueFrom } from 'rxjs'
import { AddDataHWDeviceService } from './add-data-hwDevice.service'

export interface AddDataDay {
  date: string
  formattedDate?: string
  cycleDay?: number
  typeOfDay?: string
  isPregnantDay?: boolean
  isPossiblePregnantDay?: boolean
  pregnantMoreThan8Months?: boolean
}

export interface Temperature {
  temperature?: number
  digits?: number[]
  deviating?: boolean
  temperatureState?: TemperatureState
}

let saveBatchDone: DeferredPromise | undefined
let addDataService: AddDataService

// shortcut
export function getDigitsFromTemperature(temperature?: number): number[] {
  return addDataService.getDigitsFromTemperature(temperature)
}

@Injectable({ providedIn: 'root' })
export class AddDataService {
  private accountService = inject(AccountService)
  private addDataHWDeviceService = inject(AddDataHWDeviceService)
  private analyticsService = inject(AnalyticsService)
  private dailyEntryService = inject(DailyEntryService)
  private dateService = inject(DateService)
  private healthKitService = inject(HealthKitService)
  private networkService = inject(NetworkService)
  private popupController = inject(PopupController)

  constructor() {
    addDataService = this
  }

  // Returns current entry
  // Entry come from UF or from state.modifiedEntryMap if user has made changes.
  public getEntry(date: string, ignoreStash = false): DailyEntryBM {
    const {
      userFertility: { entryMap },
      addData: { entryStash, modifiedDailyEntries },
    } = getState()

    if (!ignoreStash && entryStash?.date === date) return entryStash

    // Get entry map from UF
    // If no entry at this date, create a new
    return modifiedDailyEntries[date] || entryMap[date] || { date, dataFlags: [] }
  }

  /**
   * Restores stashed entry to modified daily entries
   */
  public restoreStashedEntry(): void {
    const {
      addData: { entryStash },
      userFertility: { entryMap },
    } = getState()
    if (!entryStash) return

    dispatch('clearEntryStash')

    if (dailyEntrySharedUtil.hasDataExceptSkipped(entryStash)) {
      this.addToModifiedDailyEntries(entryStash)
      return
    }

    const originalEntry = { ...entryMap[entryStash.date]! }
    const hasChanges = this.checkIfEntryChanged(originalEntry, entryStash)
    if (!hasChanges) return

    // We want to force modified daily entries even if it has no data
    // (useful in case when we want to undo positive pregnancy test)
    dispatch('extendModifiedDailyEntries', { [entryStash.date]: _omit(entryStash, ['updated']) })
  }

  public getDayParams(date: string): AddDataDay {
    const { account, userFertility } = getState()
    const { colorMap } = userFertility

    const day: AddDataDay = {
      date,
      cycleDay: colorMap[date]?.cd,
      isPregnantDay: false,
      isPossiblePregnantDay: false,
      pregnantMoreThan8Months: false,
    }

    const todayDate = dayjs().startOf('day')
    const today = todayDate.toISODate()

    if (colorMap[date]) {
      day.isPregnantDay = colorMap[date]!.code.defPreg
    } else if (colorMap[today]) {
      day.isPregnantDay = colorMap[today]!.code.defPreg
    }

    if (colorMap[today]) {
      day.isPossiblePregnantDay = colorMap[today]!.code.possPreg
    }

    day.typeOfDay = day.isPregnantDay ? tr('txt-day') : tr('txt-cycle-day-short')

    const format = account.lang === LANG.pt_BR ? DateFormat.SHORT : undefined // use short format for pt-BR

    day.formattedDate = this.dateService.localizeDate(dayjs(day.date), format)

    if (day.date === today) {
      day.formattedDate = tr('txt-today')
    } else if (dayjs(day.date).diff(todayDate, 'day') === -1) {
      day.formattedDate = tr('txt-yesterday')
    }

    if (day.cycleDay) {
      day.formattedDate += `, ${day.typeOfDay} ${day.cycleDay}`
    }

    if (userFertility.conceptionDate) {
      const conceptionDate = dayjs(userFertility.conceptionDate)
      const end = dayjs(date || today)

      // Add 3 weeks (same in pregnancy-week.page)
      if (end.diff(conceptionDate, 'week') + 3 >= 37) {
        day.pregnantMoreThan8Months = true
      }
    }
    return day
  }

  public getAddDataConfig(
    account: AccountTM,
    accountData: AccountDataFM,
    entry: DailyEntryBM,
    settings: AppSettingsFM | null,
    type: HardwareType,
  ): AddDataConfig {
    if (!entry) return {}

    const goal = account.goal || Goal.PREVENT
    const { appId } = account
    const dayParams = this.getDayParams(entry.date)
    const { userFertility } = getState()
    const { trackers } = settings || { trackers: allTrackersOff }
    const { withdrawalBleedEndDate, miscarriageBleedDates, postpartumBleedDates } = accountData

    const mensSegments = this.getMensSegments(
      entry.date,
      dayParams.isPregnantDay,
      trackers[AppTracker.MENS_QUANTITY],
      entry.mens,
      withdrawalBleedEndDate,
      miscarriageBleedDates,
      postpartumBleedDates,
    )
    const sexSegments = this.getSexSegments(
      entry.sex,
      entry.sexType,
      goal,
      trackers[AppTracker.MORE_SEX],
    )
    const otherSexItems = this.getOtherSexItems(entry.sex, goal, trackers[AppTracker.MORE_SEX])
    const cmQuantitySegments = this.getCervicalMucusQuantitySegments(
      goal,
      trackers[AppTracker.CM_QUANTITY],
      trackers[AppTracker.CM_CONSISTENCY],
      entry.cervicalMucusConsistency,
    )
    const cmConsistencySegments = this.getCervicalMucusConsistencySegments(
      goal,
      trackers[AppTracker.CM_CONSISTENCY],
      entry.cervicalMucusQuantity,
    )
    const lhSegments = this.getLhSegments(goal)
    const pregTestSegments = this.getPregSegments(goal)
    const painFlags = this.getPainFlags(goal, trackers[AppTracker.PAIN])
    const skinFlags = this.getSkinFlags(goal, trackers[AppTracker.SKIN])
    const deviationReasonFlags = this.getDeviationFlags(goal, type)
    const showNoLongerPregnant =
      this.shouldShowNoLongerPregnantButton(userFertility.pregnantNow, dayParams.isPregnantDay) &&
      dayParams.pregnantMoreThan8Months

    const showSensitiveTrackers =
      goal === Goal.PREVENT || goal === Goal.POSTPARTUM || goal === Goal.PREGNANT

    const moodFlags = this.getMoodFlags(goal, trackers[AppTracker.MOOD])

    const libidoSegments = this.getLibidoSegments(trackers[AppTracker.LIBIDO])

    const sleepSegments = this.getSleepSegments(trackers[AppTracker.SLEEP])

    const pregnantDay =
      (userFertility.pregnantNow && dayParams.isPregnantDay) || goal === Goal.PREGNANT

    return {
      goal,
      mensSegments,
      sexSegments,
      otherSexItems,
      libidoSegments,
      lhSegments,
      pregTestSegments,
      cmQuantitySegments,
      cmConsistencySegments,
      painFlags,
      skinFlags,
      deviationReasonFlags,
      showNoLongerPregnant,
      moodFlags,
      showSensitiveTrackers,
      pregnantDay,
      sleepSegments,
      appId,
    }
  }

  public async submitButtonPressed(entryToSave: DailyEntryBM, skip = false): Promise<void> {
    if (skip) {
      entryToSave = {
        date: entryToSave.date,
        skipped: true,
        dataFlags: [],
      }
    }

    this.addToModifiedDailyEntries(entryToSave)

    await this.saveModifiedDailyEntries(true)
  }

  public getDigitsFromTemperature(temperature?: number): number[] {
    const arr = temperature?.toFixed(2).split('') || []
    const index = arr.indexOf('.')

    if (index < 0) return []

    arr.splice(index, 1)
    return arr.map(item => parseInt(item))
  }

  public async showScienceConsentModal(): Promise<boolean> {
    const { userFertility, account, ui } = getState()
    const totalEntriesLength = Object.keys(userFertility.entryMap).length

    const requirements = [
      totalEntriesLength >= 10, // min 10 saved entries
      account.scienceConsent === undefined, // undefined science consent
      !account.demoMode, // not in demo mode
      ui.online, // online
    ]

    if (requirements.includes(false) || account.personaId === 'PERCY') return false

    const consentModal = await this.popupController.presentModal(
      {
        component: ScienceConsentModal,
      },
      'modal-scienceConsent',
    )

    const { data } = await consentModal.onDidDismiss()
    const scienceConsent = data?.scienceConsent

    if (scienceConsent !== undefined) {
      await this.accountService.patch({ scienceConsent })
    }

    return true
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async saveNoLongerPregnantDate(date: string): Promise<void> {
    await di.get(AccountService).changeGoal({
      pregnancyEndedDate: date,
    })

    void this.analyticsService.trackEvent(EVENT.NO_LONGER_PREGNANT, { pregnancyEndedDate: date })
  }

  public checkIfEntryChanged(original: DailyEntryBM, changed: DailyEntryBM): boolean {
    if (dailyEntrySharedUtil.isEmptyOrSkipped(original) && dailyEntrySharedUtil.isEmpty(changed)) {
      return false
    }

    return !_deepEquals(
      _filterEmptyValues(_omit(original, ['updated'])),
      _filterEmptyValues(_omit(changed, ['updated'])),
    )
  }

  public addToModifiedDailyEntries(entry: DailyEntryBM): void {
    const { entryMap } = getState().userFertility
    // Compare change with entry in uf and add to changes if there was any changes.
    const originalEntry = { ...entryMap[entry.date]! }
    const hasChanges = this.checkIfEntryChanged(originalEntry, entry)

    if (hasChanges) {
      const temperatureChanged =
        entry.temperature !== originalEntry.temperature ||
        entry.temperatureMeasuredTimestamp !== originalEntry.temperatureMeasuredTimestamp

      if (temperatureChanged) {
        entry.dataFlags = entry.dataFlags.filter(flag => flag !== DataFlag.DEVIATION_REASON_ALGO)
      }

      dispatch('extendModifiedDailyEntries', { [entry.date]: entry })
    } else {
      dispatch('deleteDailyEntryFromModifiedDailyEntries', entry.date)
    }
  }

  public canAddData(): boolean {
    const { account } = getState()
    const hasValidSubscription =
      (account.nextPaymentDate && dayjs().isSameOrBefore(account.nextPaymentDate, 'day')) ||
      this.accountService.isInDemoModeBeforeSelectingPlan()

    return hasValidSubscription
  }

  public getSensitiveData(entry: DailyEntryBM): any[] {
    const data = []

    const { goal } = getState().account
    if (goal !== Goal.PLAN && entry.pregTest) {
      data.push(entry.pregTest)
    }

    if (entry.dataFlags.length > 0) {
      data.push(
        ...entry.dataFlags.filter(
          flag => flag === DataFlag.MORE_EMERGENCY_PILL || flag === DataFlag.MORE_EMERGENCY_IUD,
        ),
      )
    }

    return data
  }

  // Save modified entries (called when modal dismisses or in unplanned pregnancy flow)
  public async saveModifiedDailyEntries(ignoreStash = false): Promise<DailyEntrySaveInput[]> {
    const { ui, addData, glossary, guides, quizzes } = getState()

    if (!ignoreStash && addData.entryStash) this.restoreStashedEntry()

    const canAddData = this.canAddData()
    const isOffline = !ui.online
    if (!canAddData || isOffline) {
      void this.analyticsService.trackEvent(EVENT.ADD_DATA_SAVE_TO_LOCAL, {
        canAddData,
        isOffline,
      })
      return []
    }

    // restoreStashedEntry mutates the state, fetch addData.modifiedDailyEntries again
    const modifiedEntries = _stringMapValues(getState().addData.modifiedDailyEntries)

    if (modifiedEntries.length) {
      window.addDataCloseSaveDataStarted = Date.now()
    } else {
      window.addDataCloseNoDataStarted = Date.now()
      return []
    }
    const dailyEntries: DailyEntrySaveInput[] = []

    modifiedEntries.forEach(e => {
      const entry = dailyEntrySharedUtil.dailyEntryToSaveInput(e)

      dailyEntries.push(entry)
    })

    if (dailyEntries.length) {
      if (this._showDemoAlertIfOnDemo()) {
        dispatch('setGhostLoader', false)
        return []
      }

      if (saveBatchDone) {
        await saveBatchDone
      }

      const { t3Meta } = addData

      // initialize new promises
      saveBatchDone = pDefer<void>()

      const input: DailyEntrySaveBatchInput = {
        entries: dailyEntries,
        glossaryHash: glossary.glossaryHash,
        guideHash: guides.guideHash,
        quizHash: quizzes.quizHash,
        t3Meta,
      }

      await this.saveBatch(input) // async

      saveBatchDone.resolve()
    }
    return dailyEntries
  }

  public _showDemoAlertIfOnDemo(): boolean {
    if (!getState().account.demoMode) return false

    dispatch('clearModifiedDailyEntries')

    void this.popupController.presentAlert(
      {
        header: tr('demo-add-data-alert-title'),
        message: tr('demo-add-data-alert-body'),
        buttons: [
          {
            text: tr('demo-add-data-alert-btn'),
            role: 'cancel',
          },
        ],
      },
      'alert-addData-demo',
      Priority.HIGH,
    )

    return true
  }

  @decorate({
    loaderType: LoaderType.GHOST,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  private async saveBatch(input: DailyEntrySaveBatchInput): Promise<void> {
    await this.dailyEntryService.saveBatch(input).catch(async err => {
      // Got a transport error, set user offline
      if (err instanceof HttpRequestError && !err.data.responseStatusCode) {
        this.setOffline()
      }

      throw err
    })

    dispatch('clearModifiedDailyEntries')
    dispatch('clearT3Metadata')
    dispatch('clearEntryStash')

    if (!getState().userSettings.hkExportEnabled) return
    const ufDays = await firstValueFrom(di.get(UFService).ufDays$)

    const earliestDate = _sortBy(input.entries, de => de.date)[0]?.date
    void this.healthKitService.exportFertilityData(earliestDate, ufDays)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  public async postNoLongerPregnantDate(date: string): Promise<void> {
    await di.get(AccountService).changeGoal({
      pregnancyEndedDate: date,
    })
  }

  @_Memo()
  public getDeviationFlags(goal: Goal, type: HardwareType): DataFlag[] | undefined {
    const flags = [DataFlag.DEVIATION_REASON_SICK]

    if (type === 'thermometer') {
      flags.push(DataFlag.DEVIATION_REASON_SLEEP)
    }

    if (goal !== Goal.PREGNANT) {
      flags.push(DataFlag.DEVIATION_REASON_ALCOHOL)
    }

    return flags
  }

  private getMoodFlags(goal: Goal, enabled?: boolean): DataFlag[] | undefined {
    if (!enabled) return

    if (goal === Goal.PREGNANT) {
      return MOOD_FLAGS_FOR_PREGNANT
    }

    return MOOD_FLAGS
  }

  @_Memo()
  public getMensSegments(
    date: string,
    isPregnantDay?: boolean,
    optionsEnabled?: boolean,
    mensSelected?: Mens,
    withdrawalBleedEndDate?: string,
    miscarriageBleedDates: string[] = [],
    postpartumBleedDates: string[] = [],
  ): SuperSegmentItem[] {
    const items = [bleedingItems.Spotting]

    if (
      mensSelected === Mens.WITHDRAWAL ||
      (withdrawalBleedEndDate && date < withdrawalBleedEndDate)
    ) {
      items.unshift({
        ...(optionsEnabled ? bleedingItems.WithdrawalWithOptions : bleedingItems.Withdrawal),
        title: 'txt-menstruation-withdrawal',
      })
    }

    const miscarriageIntervals = miscarriageBleedDates.map(interval => DateInterval.parse(interval))
    const lastMiscarriageBleed = _lastOrUndefined(miscarriageIntervals)?.end.toISODate()

    // only check for miscarriage bleed days for dates that are before the last bleed end date
    if (
      mensSelected === Mens.MISCARRIAGE_BLEEDING ||
      (lastMiscarriageBleed && lastMiscarriageBleed >= date)
    ) {
      const showMiscarriageBleed = miscarriageIntervals.some(interval => interval.includes(date))

      if (mensSelected === Mens.MISCARRIAGE_BLEEDING || showMiscarriageBleed) {
        items.unshift({
          ...(optionsEnabled ? bleedingItems.MiscarriageWithOptions : bleedingItems.Miscarriage),
        })
      }
    }

    const postpartumIntervals = postpartumBleedDates.map(interval => DateInterval.parse(interval))
    const lastPostpartumBleed = _lastOrUndefined(postpartumIntervals)?.end.toISODate()

    // only check for postpartum bleed days for dates that are before the last bleed end date
    if (
      mensSelected === Mens.POSTPARTUM_BLEEDING ||
      (lastPostpartumBleed && lastPostpartumBleed >= date)
    ) {
      const showPostpartumBleed = postpartumIntervals.some(interval => interval.includes(date))

      if (mensSelected === Mens.POSTPARTUM_BLEEDING || showPostpartumBleed) {
        items.unshift({
          ...(optionsEnabled ? bleedingItems.PostpartumWithOptions : bleedingItems.Postpartum),
        })
      }
    }

    // Mens if it's selected or if its a non-pregnant day with only spotting option so far
    if (mensSelected === Mens.MENSTRUATION || (!isPregnantDay && items.length === 1)) {
      items.unshift(optionsEnabled ? bleedingItems.MensWithOptions : bleedingItems.Mens)
    }

    return items
  }

  @_Memo()
  public getSexSegments(
    addedSex?: HadSex,
    addedSexType?: SexType,
    goal?: Goal,
    optionsEnabled?: boolean,
  ): SuperSegmentItem[] | undefined {
    if (goal === Goal.PREVENT || goal === Goal.POSTPARTUM) {
      return [sexItems.ProtectedWithOptions, sexItems.UnprotectedWithOptions, sexItems.None]
    }

    if (optionsEnabled) return

    // If sex type is previously added on this day (from prevent/postpartum), show it as an option
    if (addedSexType && SEX_TYPE_PROTECTED_VALUES.has(addedSexType)) {
      return [sexItems.Yes, sexItems.ProtectedWithOptions, sexItems.No]
    }
    if (addedSexType && SEX_TYPE_UNPROTECTED_VALUES.has(addedSexType)) {
      return [sexItems.UnprotectedWithOptions, sexItems.No]
    }

    // If protected sex is previously added on this day, show it as an option
    if (addedSex === HadSex.YES_PROTECTED) return [sexItems.Yes, sexItems.Protected, sexItems.No]

    return [sexItems.Yes, sexItems.No]
  }

  @_Memo()
  public getOtherSexItems(
    addedSex?: HadSex,
    goal?: Goal,
    optionsEnabled?: boolean,
  ): SuperSegmentItem[] | undefined {
    if (!optionsEnabled) return

    const othersexItems = [
      otherSexItems['SEX_MASTURBATION'],
      otherSexItems['SEX_ORAL'],
      otherSexItems['SEX_TOUCHING'],
      otherSexItems['SEX_TOYS'],
      otherSexItems['SEX_ANAL'],
    ].filter(Boolean) as SuperSegmentItem[]

    if (goal === Goal.PREVENT || goal === Goal.POSTPARTUM) {
      return othersexItems
    }

    // If protected sex is previously added on this day, show it as an option
    if (addedSex === HadSex.YES_PROTECTED) othersexItems.unshift(sexItems.Protected)

    return [sexItems.Vaginal, ...othersexItems, sexItems.None]
  }

  @_Memo()
  public getLhSegments(goal: Goal): SuperSegmentItem[] | undefined {
    if (goal === Goal.PREGNANT) return

    const items = [lhItems.Positive, lhItems.Negative]

    if (window.Capacitor.isNativePlatform()) items.push(lhItems.Scanner)

    return items
  }

  @_Memo()
  public getPregSegments(goal: Goal, inSensitiveModal = false): SuperSegmentItem[] | undefined {
    if (inSensitiveModal || goal === Goal.PLAN || goal === Goal.RECOVERY) {
      return [pregItems.Positive, pregItems.Negative]
    }
  }

  @_Memo()
  public getPainFlags(goal: Goal, enabled?: boolean): DataFlag[] | undefined {
    if (!enabled) return

    if (goal === Goal.PREGNANT) {
      return PAIN_FLAGS_FOR_PREGNANT
    }

    if (goal === Goal.POSTPARTUM) {
      return PAIN_FLAGS_POSTPARTUM
    }

    return PAIN_FLAGS
  }

  @_Memo()
  public getSkinFlags(goal: Goal, enabled?: boolean): DataFlag[] | undefined {
    if (!enabled) return

    if (goal === Goal.PREGNANT) {
      return SKIN_FLAGS_PREGNANT
    }

    if (goal === Goal.POSTPARTUM) {
      return SKIN_FLAGS_POSTPARTUM
    }

    return SKIN_FLAGS
  }

  @_Memo()
  public getCervicalMucusQuantitySegments(
    goal: Goal,
    enabled?: boolean,
    consistencyEnabled?: boolean,
    selectedConsistency?: CervicalMucusConsistency,
  ): SuperSegmentItem[] | undefined {
    if (!enabled) return

    // show only quantity
    if (!consistencyEnabled || goal === Goal.PREGNANT) {
      const quantity = [
        cervicalMucusItems.QuantityNone,
        cervicalMucusItems.QuantityLight,
        cervicalMucusItems.QuantityMedium,
        cervicalMucusItems.QuantityHeavy,
      ]

      if (selectedConsistency) {
        quantity.splice(0, 1) // Remove NONE if there is a selected consistency
      }

      return quantity
    }

    return selectedConsistency
      ? [cervicalMucusItems.Quantity]
      : [cervicalMucusItems.QuantityWithNone]
  }

  @_Memo()
  public getCervicalMucusConsistencySegments(
    goal: Goal,
    enabled?: boolean,
    selectedQuantity?: DataQuantity,
  ): SuperSegmentItem[] | undefined {
    if (goal === Goal.PREGNANT || !enabled) return

    if (selectedQuantity === DataQuantity.NONE) return [] // return empty array, since the tracker is available but currently doesnt have any options

    return [cervicalMucusItems.Consistency]
  }

  @_Memo()
  private getLibidoSegments(enabled?: boolean): SuperSegmentItem[] | undefined {
    if (!enabled) return

    return [libidoItems.Low, libidoItems.Medium, libidoItems.High]
  }

  @_Memo()
  private getSleepSegments(enabled?: boolean): SuperSegmentItem[] | undefined {
    if (!enabled) return

    return [sleepItems.Poor, sleepItems.Okay, sleepItems.Restful]
  }

  @_Memo()
  public getEmergencyContraceptiveSegments(goal: Goal): SuperSegmentItem[] | undefined {
    if (goal === Goal.PREVENT || goal === Goal.POSTPARTUM) {
      return [emergencyItems.Pill, emergencyItems.IUD]
    }
  }

  @_Memo()
  public shouldShowNoLongerPregnantButton(pregnantNow?: boolean, isPregnantDay?: boolean): boolean {
    return !!pregnantNow && !!isPregnantDay
  }

  public getTemperature(date: string): Temperature | undefined {
    const entry = this.getEntry(date)
    const state = this.addDataHWDeviceService.getDataState(entry)

    if (!entry.temperature) return { temperatureState: { state } }

    return {
      temperature: entry.temperature,
      digits: this.getDigitsFromTemperature(entry.temperature),
      deviating: !!entry.dataFlags.filter(flag => DEVIATION_REASON_FLAGS.has(flag)).length, // todo uf
      temperatureState: { state },
    }
  }

  public getTemperatureState(entry: DailyEntryBM): TemperatureState {
    const state = this.addDataHWDeviceService.getDataState(entry)
    const formattedTimestamp = this.addDataHWDeviceService.getFormattedTimestamp(entry)

    return { state, formattedTimestamp }
  }

  private setOffline(): void {
    logUtil.log('[saveBatch] Tried to save data but got transport error')

    this.networkService.goOffline()
  }

  public async showOuraPermissionsModalIfNeeded(
    hwChanges: StringMap<HardwareId>,
    account: AccountTM,
    userSettings: UserSettings,
  ): Promise<boolean> {
    if (account.hwId !== HardwareId.OURA || userSettings.askedForOuraNotificationPermissions) {
      return false
    }

    const ouraChangeDate = Object.entries(hwChanges).find(
      ([_date, value]) => value === HardwareId.OURA,
    )?.[0]
    const weekAgo = dayjs().subtract(7, 'd')
    const isOlderThanWeek = ouraChangeDate
      ? weekAgo.isSameOrAfter(ouraChangeDate)
      : account.regDate && weekAgo.isSameOrAfter(account.regDate)

    if (isOlderThanWeek && !(await di.get(NotificationService).hasPermission())) {
      void di.get(NotificationService).showNotificationsDisabledAlert(false, true)
      dispatch('extendUserSettings', { askedForOuraNotificationPermissions: true })
      return true
    }

    return false
  }

  public syncedBluetoothTemperatureToDailyEntry(input: SyncedTemperature): DailyEntryBM {
    const { timestamp, temperature } = input

    const date = dayjs.unix(timestamp).toISODate()
    const entry = this.getEntry(date, true)

    return {
      ...entry,
      temperature,
      updated: undefined,
      temperatureMeasuredTimestamp: timestamp,
    }
  }

  public addBluetoothEntriesToModifiedEntries(entries: DailyEntryBM[]): void {
    entries.forEach(entry => {
      // We find entry to merge fertility data like periods
      const existingEntry = this.getEntry(entry.date)

      this.addToModifiedDailyEntries({
        ...existingEntry,
        temperature: entry.temperature,
        temperatureMeasuredTimestamp: entry.temperatureMeasuredTimestamp,
      })
    })
  }
}
